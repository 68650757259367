import { select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectIsRoomInitialized, selectIsRoomReady } from './room.selector';

export const filterRoomReady = pipe(
  select(selectIsRoomReady),
  filter(isReady => isReady),
);

export const filterRoomInitialized = pipe(
  select(selectIsRoomInitialized),
  filter(isReady => isReady),
);
