import { User } from '../user.model';
import { PLGuidService } from '@common/services/GuidService';

export interface UserState {
  isAuthenticated: boolean;
  userId: string;
  user: User;
  groups: Record<string, boolean>;
}

const uuid = new PLGuidService().generateUUID();

export const initialState: UserState = {
  isAuthenticated: false,
  userId: uuid,
  user: {
    uuid,
    first_name: 'guest',
    last_name: '',
    display_name: 'guest',
    is_superuser: false,
  },
  groups: {},
};
