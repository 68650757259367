import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectIsHijackedUser } from '../../modules/user/store';
import { User } from '../../modules/user/user.model';
import { AppState } from '../../store';
import { PLGlobalUtilService } from './pl-global-util.service';

// boolean
const KEY_STATE_DRAWER_EXPANDED = 'plUiState.assume-login-bar-expanded';

@Injectable({ providedIn: 'root' })
export class PLHijackHelperService {
  private isExpanded = false;
  public isHijacked = false;
  constructor(
    private store: Store<AppState>,
    private plGlobalUtil: PLGlobalUtilService,
  ) {
    this.store.select(selectIsHijackedUser).subscribe(isHijacked => {
      this.isHijacked = isHijacked;
    });
    this.checkForCookieValues();
    window.setInterval(() => this.checkForCookieValues(), 5000);
  }

  private checkForCookieValues() {
    const expandedCookie = this.plGlobalUtil.readCookie(
      KEY_STATE_DRAWER_EXPANDED,
    );
    this.isExpanded = expandedCookie && expandedCookie === 'true';
  }

  isUserHijacked(user: User) {
    return (
      user &&
      user.userStatus &&
      user.userStatus.plru &&
      user.userStatus.plru !== user.userStatus.sub
    );
  }

  setDrawerExpand(value = false) {
    this.plGlobalUtil.createCookie(KEY_STATE_DRAWER_EXPANDED, value, 1000);
    this.isExpanded = value;
  }

  get isCurrentUserHijacked() {
    return this.isHijacked;
  }

  get isDrawerExpanded() {
    return this.isExpanded;
  }
}
