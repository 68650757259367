import { Injectable } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';

export enum NetworkStatus {
  online = 'online',
  offline = 'offline',
  reconnecting = 'reconnecting',
}

@Injectable({ providedIn: 'root' })
export class NetworkStatusService {
  static get currentValue() {
    return window?.navigator?.onLine
      ? NetworkStatus.online
      : NetworkStatus.offline;
  }

  getValue() {
    return merge(this.onOffline(), this.onOnline()).pipe(
      startWith(NetworkStatusService.currentValue),
    );
  }

  private onOffline() {
    return fromEvent(window, 'offline').pipe(mapTo(NetworkStatus.offline));
  }

  private onOnline() {
    return fromEvent(window, 'online').pipe(mapTo(NetworkStatus.online));
  }
}
