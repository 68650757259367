import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PLApiAbstractService } from './pl-api-abstract.service';

export interface Location {
  uuid: string;
  created: string;
  modified: string;
  name: string;
  state: string;
  type: string;
  record_tracking_type: boolean;
  tech_check_status: string;
  parent_organization: ParentOrganization;
  clinical_coordinator: ClinicalCoordinator;
  is_active: boolean;
  is_active_for_cam_billing: boolean;
  is_rsm_school: boolean;
  derived_rate_holder: DerivedRateHolder;
}

export interface ParentOrganization {
  uuid: string;
  created: string;
  modified: string;
  name: string;
  is_rsm_school: boolean;
  is_sandbox: boolean;
}

export interface ClinicalCoordinator {
  uuid: string;
  created: string;
  modified: string;
  name: string;
  is_rsm_school: boolean;
  is_sandbox: boolean;
}

export interface DerivedRateHolder {
  uuid: string;
  created: string;
  modified: string;
  name: string;
  is_rsm_school: boolean;
  is_sandbox: boolean;
}

@Injectable()
export class PLApiLocationsService {
  private locations: any[] = [];

  constructor(private plApiAbstract: PLApiAbstractService) {}

  get(params1: any = {}, options1: any = {}) {
    return new Observable((observer: any) => {
      this.plApiAbstract.get('locations', params1, '', options1).subscribe(
        (res: any) => {
          this.locations = res;
          observer.next(res);
        },
        (err: any) => {
          observer.error(err);
        },
      );
    });
  }
}
