import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import {
  map,
  switchMap,
  concatMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { filterRoomReady } from '@room/store/room.operators';
import { AppActions } from './app.actions';
import { AppModelService } from '../app-model.service';
import { LayoutMode } from './app.model';
import { selectLayoutMode } from './app.selectors';

@Injectable()
export class AppEffects {
  remoteValuesChanged$ = createEffect(() => {
    return this.store.pipe(
      filterRoomReady,
      switchMap(() => this.appModelService.onChanged()),
      map(data =>
        AppActions.updateFromRemote({
          data,
        }),
      ),
    );
  });

  setIsMouseShared$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setCursorSharing),
      concatMap(({ isCursorShared, isToggleDisabled }) => {
        return this.appModelService
          .update({ isCursorShared, isToggleDisabled })
          .pipe(
            map(() =>
              AppActions.setCursorSharingSuccess({
                isCursorShared,
                isToggleDisabled,
              }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('SET_MOUSE_SHARING', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  setClientMouseClick$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setClientMouseClick),
      concatMap(({ isClientClickMuted }) => {
        return this.appModelService.update({ isClientClickMuted }).pipe(
          map(() =>
            AppActions.setClientMouseClickSuccess({ isClientClickMuted }),
          ),
          catchError(error => {
            const errorObj = {
              error,
            };
            console.error('SET_CLIENT_CLICK', errorObj);
            return of(AppActions.updateFailed(errorObj));
          }),
        );
      }),
    );
  });

  setLayoutMode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setLayoutMode),
      concatMap(({ layoutMode }) => {
        return this.appModelService.update({ layoutMode }).pipe(
          map(() => AppActions.setLayoutModeSuccess({ layoutMode })),
          catchError(error => {
            const errorObj = {
              error,
            };
            console.error('SET_LAYOUT_MODE', errorObj);
            return of(AppActions.updateFailed(errorObj));
          }),
        );
      }),
    );
  });

  hideWhiteboardTools$ = createEffect(
    () => {
      return this.store.pipe(
        filterRoomReady,
        switchMap(() => {
          return this.store.select(selectLayoutMode).pipe(
            switchMap(layoutMode => {
              const isFullscreen = layoutMode !== LayoutMode.compact;

              if (!isFullscreen) {
                return EMPTY;
              }

              return this.appModelService
                .update({
                  whiteboardActive: false,
                })
                .pipe(
                  catchError(err => {
                    console.warn('sync whiteboard with layoutMode', err);
                    return of(null);
                  }),
                );
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  setIsCentralContentVisible$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setIsCentralContentVisible),
      concatMap(({ isVisible }) => {
        return this.appModelService
          .update({ isCentralContentVisible: isVisible })
          .pipe(
            map(() =>
              AppActions.setIsCentralContentVisibleSuccess({ isVisible }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('SET_CENTRAL_CONTENT_VISIBLE', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  setCentralContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setCentralContent),
      concatMap(({ contentType, contentDetail }) => {
        return this.appModelService
          .update({
            currentCentralContent: {
              contentType,
              contentDetail: contentDetail || null,
            },
          })
          .pipe(
            map(() =>
              AppActions.setCentralContentSuccess({
                contentType,
                contentDetail,
              }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('SET_CENTRAL_CONTENT', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  setCentralContentSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setCentralContentSuccess),
      map(() => {
        return AppActions.setIsCentralContentVisible({ isVisible: true });
      }),
    );
  });

  // whenever setCentralContentSuccess is dispatched, backup the activity history to firebase
  backupActivityHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setCentralContentSuccess),
      withLatestFrom(this.store.select(state => state.app.activityHistory)), // Get the latest activityHistory from the store
      switchMap(([action, activityHistory]) => {
        return this.appModelService.update({ activityHistory }).pipe(
          map(() =>
            AppActions.activityHistoryBackupSuccess({ activityHistory }),
          ),
          catchError(error => {
            const errorObj = {
              error,
            };
            console.error('backup history error: ', errorObj);
            return of(AppActions.updateFailed(errorObj));
          }),
        );
      }),
    ),
  );

  //store activity history in undo value and delete the history
  deleteActivityHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.deleteActivityHistory),
      withLatestFrom(this.store.select(state => state.app.activityHistory)),
      switchMap(([action, activityHistory]) => {
        const undoDeleteActivityHistory = activityHistory;
        return this.appModelService
          .update({ activityHistory: [], undoDeleteActivityHistory })
          .pipe(
            map(() =>
              AppActions.deleteActivityHistorySuccess({
                undoDeleteActivityHistory,
              }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('DELETE_ACTIVITY_HISTORY', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  // on undo delete activity history, restore the activity history from the undo value
  undoDeleteActivityHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.undoDeleteActivityHistory),
      withLatestFrom(
        this.store.select(state => state.app.undoDeleteActivityHistory),
      ),
      switchMap(([action, undoDeleteActivityHistory]) => {
        return this.appModelService
          .update({ activityHistory: undoDeleteActivityHistory })
          .pipe(
            map(() =>
              AppActions.activityHistoryBackupSuccess({
                activityHistory: undoDeleteActivityHistory,
              }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('UNDO_DELETE_ACTIVITY_HISTORY', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  setCurrentDrawerActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setCurrentDrawerActivity),
      concatMap(({ activity }) => {
        return this.appModelService
          .update({
            currentDrawerActivity: activity,
          })
          .pipe(
            map(() =>
              AppActions.setCurrentDrawerActivitySuccess({
                activity,
              }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('SET_DRAWER_ACTIVITY', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  setIsTimestampTrackingDisabled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setTimestampTracking),
      concatMap(({ isTimestampTrackingDisabled }) => {
        return this.appModelService
          .update({ isTimestampTrackingDisabled })
          .pipe(
            map(() =>
              AppActions.setTimestampTrackingSuccess({
                isTimestampTrackingDisabled,
              }),
            ),
            catchError(error => {
              const errorObj = {
                error,
              };
              console.error('SET_TIMESTAMP_TRACKING', errorObj);
              return of(AppActions.updateFailed(errorObj));
            }),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private appModelService: AppModelService,
  ) {}
}
