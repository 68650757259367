import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { DeviceInfo, MediaState, MediaStateExtras } from './media.model';
import { MediaActions } from './media.actions';

export const mediaSelectId = (kind: MediaDeviceKind, deviceId: string) => {
  return `${kind}-${deviceId}`;
};

export const mediaEntityAdapter = createEntityAdapter<DeviceInfo>({
  selectId: ({ kind, deviceId }) => mediaSelectId(kind, deviceId),
});

export const initialState: MediaState =
  mediaEntityAdapter.getInitialState<MediaStateExtras>({
    status: 'loading',
  });

export const reducer = createReducer(
  initialState,
  on(MediaActions.refresh, (state) => {
    return {
      status: 'loading',
      error: null,
      ...state,
    };
  }),
  on(MediaActions.refreshSuccess, (state, { devices }) => {
    const newState = mediaEntityAdapter.setAll(devices, state);

    newState.status = 'success';
    newState.error = null;

    return newState;
  }),
  on(MediaActions.refreshError, (state, action) => {
    return {
      ...state,
      error: action.error,
      status: 'error' as MediaState['status'],
    };
  }),
);
