import { Injectable } from '@angular/core';

export const LazyLoadScripts = {
  speedOfMe: 'https://speedof.me/api/api.js',
  pdfPrint: './assets/js/pdf-print.js',
};
const SCRIPT_TIMEOUT = 10000;
@Injectable({ providedIn: 'root' })
export class LazyScriptLoaderService {
  private loadedScripts: string[] = [];
  private promiseDict: Record<string, Promise<void>> = {};

  loadScript(script: string, id = null): Promise<void> {
    if (this.promiseDict[script]) {
      return this.promiseDict[script];
    }
    const promise = new Promise<void>((resolve, reject) => {
      if (this.loadedScripts.includes(script)) {
        resolve();
        return;
      }
      const timeoutHandler = setTimeout(
        () => reject('Script load timeout.'),
        SCRIPT_TIMEOUT,
      );
      let scriptEle = document.createElement('script');
      if (id) {
        scriptEle.setAttribute('id', id);
      }
      scriptEle.setAttribute('src', script);
      scriptEle.addEventListener('load', () => {
        clearTimeout(timeoutHandler);
        this.loadedScripts.push(script);
        resolve();
      });

      scriptEle.addEventListener('error', err => {
        clearTimeout(timeoutHandler);
        document.body.removeChild(scriptEle);
        reject(`Script load error: ${err}`);
      });
      document.body.appendChild(scriptEle);
    });
    promise.finally(() => {
      delete this.promiseDict[script];
    });
    this.promiseDict[script] = promise;
    return promise;
  }
}
