import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { ViewMode } from '../pl-snackbar.component';

export type PlSnackbarWrapperData = {
  title: string;
  description?: string;
  actionLabel?: string;
  dismissible?: boolean;
  disableAction?: boolean;
  mode?: ViewMode;
};

@Component({
  selector: 'pl-pl-snackbar-wrapper',
  templateUrl: './pl-snackbar-wrapper.component.html',
  styleUrls: ['./pl-snackbar-wrapper.component.scss'],
})
export class PlSnackbarWrapperComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<PlSnackbarWrapperComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: PlSnackbarWrapperData,
  ) {}

  protected onDismiss() {
    this.snackBarRef.dismiss();
  }

  protected onActionClick() {
    this.snackBarRef.dismissWithAction();
  }
}
