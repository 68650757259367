import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PLApiAbstractService } from './pl-api-abstract.service';
import { PLApiBillingCodesService } from './pl-api-billing-codes.service';
import { PLApiLocationsService } from './pl-api-locations.service';
import { PLApiFileAmazonService } from './pl-api-file-amazon.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  providers: [
    PLApiAbstractService,
    PLApiBillingCodesService,
    PLApiLocationsService,
    PLApiFileAmazonService,
  ],
})
export class PLApiModule {}

export { PLApiAbstractService } from './pl-api-abstract.service';
export { PLApiBillingCodesService } from './pl-api-billing-codes.service';
export { PLApiLocationsService } from './pl-api-locations.service';
export { PLApiFileAmazonService } from './pl-api-file-amazon.service';
