import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class WaitingRoomHelperService {
  private clinicianUsername: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
  ) {
    this.route.firstChild.params.subscribe(data => {
      this.clinicianUsername = data.clinician_username;
    });
  }

  getClinicianUsername() {
    return this.clinicianUsername;
  }

  redirectToClinicianRoom = () => {
    this.zone.run(() => {
      if (this.clinicianUsername) {
        this.router.navigate([this.clinicianUsername, 'room'], {
          replaceUrl: true,
        });
      } else {
        this.router.navigate(['']);
      }
    });
  };

  redirectToStudentLogin() {
    this.router.navigate([this.clinicianUsername], {});
  }

  redirectToWaitingRoom() {
    this.router.navigate([this.clinicianUsername, 'waiting-room'], {
      replaceUrl: true,
    });
  }
}
