import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PLWindowWarningDialogComponent } from './window-warning-dialog.component';
import { PlWindowWarningComponent } from './window-warning.component';
import { CommonModule } from '@angular/common';

const exported = [PLWindowWarningDialogComponent, PlWindowWarningComponent];

@NgModule({
  imports: [CommonModule, MatDialogModule],
  declarations: [...exported],
  exports: [...exported],
  providers: [],
})
export class PLWindowWarningModule {}
