import { createAction, props } from '@ngrx/store';
import { UserRoom } from '@library/store';
import { QueueItem, Queues } from '@library/common/services/queues.service';
import { EditQueueDetailsData } from '../my-library/queues/edit-queue-details/edit-queue-details.component';

const setUserRoom = createAction(
  '[Library] Set User Room',
  props<{ rooms: any[] }>(),
);
const setUserRoomSuccess = createAction(
  '[Library] Set User Room Success',
  props<{ userRoom: UserRoom }>(),
);
const setQueuesServiceInitialized = createAction(
  '[Library] Set Queues Service Initialized',
  props<{ initialized: boolean }>(),
);
const fetchUserQueues = createAction('[Library] Fetch User Queues');
const fetchUserQueuesSuccess = createAction(
  '[Library] Fetch User Queues Success',
  props<{ userQueues: Queues }>(),
);
const createUserQueue = createAction(
  '[Library] Create User Queue',
  props<{ queue: QueueItem }>(),
);
const createUserQueueSuccess = createAction(
  '[Library] Create User Queue Success',
  props<{ queue: QueueItem }>(),
);
const deleteUserQueue = createAction(
  '[Library] Delete User Queue',
  props<{ queueId: string; showNotification?: boolean }>(),
);
const deleteUserQueueSuccess = createAction(
  '[Library] Delete User Queue Success',
  props<{ queueId: string; showNotification: boolean }>(),
);
const deleteQueueNotificationDismissed = createAction(
  '[Library] Delete Queue Notification Dismissed',
);
const deleteQueueNotificationDismissedByAction = createAction(
  '[Library] Delete Queue Notification Dismissed By Action',
  props<{ queueId: string }>(),
);
const restoreDeletedQueue = createAction(
  '[Library] Restore Deleted Queue',
  props<{ queueId: string }>(),
);
const restoreDeletedQueueSuccess = createAction(
  '[Library] Restore Deleted Queue Success',
  props<{ queue: QueueItem }>(),
);
const showEditQueueDialog = createAction(
  '[Library] Show Edit Queue Dialog',
  props<{ queue: QueueItem; data?: Partial<EditQueueDetailsData> }>(),
);
const editQueueDialogDismissed = createAction(
  '[Library] Edit Queue Dialog Dismissed',
);
const editUserQueue = createAction(
  '[Library] Edit User Queue',
  props<{ queueId: string; queue: Partial<QueueItem> }>(),
);
const editUserQueueSuccess = createAction(
  '[Library] Edit User Queue Success',
  props<{ queue: QueueItem }>(),
);
const duplicateQueue = createAction(
  '[Library] Duplicate Queue',
  props<{ queue: QueueItem; navigateToIfUndoPath?: string }>(),
);
const duplicateQueueSuccess = createAction(
  '[Library] Duplicated Queue Success',
  props<{ queue: QueueItem; navigateToIfUndoPath?: string }>(),
);
const duplicateQueueNotificationDismissed = createAction(
  '[Library] Duplicate Queue Notification Dismissed',
);
const duplicateQueueNotificationDismissedByAction = createAction(
  '[Library] Duplicate Queue Notification Dismissed By Action',
  props<{ queueId: string; navigateToIfUndoPath?: string }>(),
);
const updateQueuesOrder = createAction(
  '[Library] Update Queues Order',
  props<{ order: string[] }>(),
);
const updateQueuesOrderSuccess = createAction(
  '[Library] Update Queues Order Success',
  props<{ order: string[] }>(),
);

export const LibraryActions = {
  setUserRoom,
  setUserRoomSuccess,
  setQueuesServiceInitialized,
  fetchUserQueues,
  fetchUserQueuesSuccess,
  createUserQueue,
  createUserQueueSuccess,
  deleteUserQueue,
  deleteUserQueueSuccess,
  deleteQueueNotificationDismissed,
  deleteQueueNotificationDismissedByAction,
  restoreDeletedQueue,
  restoreDeletedQueueSuccess,
  showEditQueueDialog,
  editQueueDialogDismissed,
  editUserQueue,
  editUserQueueSuccess,
  duplicateQueue,
  duplicateQueueSuccess,
  duplicateQueueNotificationDismissed,
  duplicateQueueNotificationDismissedByAction,
  updateQueuesOrder,
  updateQueuesOrderSuccess,
};
