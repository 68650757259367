import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgInlineNgPluginService } from '@root/src/build/svg-inline-ng-plugin.service';

@Injectable({ providedIn: 'root' })
export class SvgToMatIconsRegistryService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private svgInlineNgPluginService: SvgInlineNgPluginService,
  ) {}

  register() {
    for (const icon in this.svgInlineNgPluginService.svgs) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.svgInlineNgPluginService.svgs,
          icon,
        )
      ) {
        const svg = this.svgInlineNgPluginService.svgs[icon];
        this.matIconRegistry.addSvgIconLiteral(
          icon,
          this.domSanitzer.bypassSecurityTrustHtml(svg.html),
        );
      }
    }
  }
}
