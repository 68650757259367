import { Observable } from 'rxjs';

export const teardown = <T>(onComplete: (lastValue: T) => void) => {
  return (source$: Observable<T>) => {
    let lastValue: T;
    return new Observable<T>((subscriber) => {
      const subscription = source$.subscribe({
        ...subscriber,
        next: (val) => {
          lastValue = val;
          subscriber.next(val);
        },
      });
      subscriber.add(() => {
        onComplete(lastValue);
        subscription.unsubscribe();
      });
    });
  };
};
