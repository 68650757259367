<div
  class="pl-snackbar"
  [class.pl-snackbar-dark]="mode === 'dark'"
  [class.pl-snackbar-multiline]="multiline"
  [class.pl-snackbar-column]="useColumn">
  <div class="pl-snackbar-content" #snackbarContent>
    <ng-content select="p"></ng-content>
  </div>
  <div class="pl-snackbar-buttons">
    <button
      mat-button
      color="primary"
      class="pl-snackbar-action"
      *ngIf="!disableAction"
      [disableRipple]="true"
      (click)="handleActionClicked()">
      {{ actionLabel }}
    </button>
    <button
      mat-icon-button
      class="pl-snackbar-dismiss"
      *ngIf="dismissible"
      [disableRipple]="true"
      (click)="handleDismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
