import { initLogger } from '@common/services/logger/logger.service';
initLogger();

import moment from 'moment';
import 'zone.js';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// set the default format for moment string rendering
moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error('App error', err));
