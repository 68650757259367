import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  WaitingRoomGuestAdmissionEffects,
  WaitingRoomGuestKnockEffects,
  WaitingRoomHostAdmissionEffects,
  WaitingRoomHostKnockEffects,
  WaitingRoomInitConferenceEffects,
} from './store/effects';
import { WaitingRoomConferenceService } from './waiting-room-conference.service';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      WaitingRoomInitConferenceEffects,
      WaitingRoomHostKnockEffects,
      WaitingRoomHostAdmissionEffects,
      WaitingRoomGuestKnockEffects,
      WaitingRoomGuestAdmissionEffects,
    ]),
  ],
  exports: [],
  declarations: [],
  providers: [WaitingRoomConferenceService],
})
export class WaitingRoomModule {}
