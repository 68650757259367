import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConferenceReconnectionService } from '@common/conference/conference-reconnection.service';
import { WaitingRoomConferenceService } from '../../waiting-room-conference.service';

import * as WaitingRoomActions from '../waiting-room.actions';

@Injectable()
export class WaitingRoomInitConferenceEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.init),
      switchMap(tokbox => {
        this.waitingRoomConference.setConfig({
          tokbox,
          name: 'Waiting Room',
        });
        return this.waitingRoomConference.init().pipe(
          map(() => WaitingRoomActions.initSuccess()),
          catchError(err => {
            console.error('WaitingRoom connection error', err);
            return of(WaitingRoomActions.initError({ error: err }));
          }),
        );
      }),
    );
  });

  reconnect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.initSuccess),
      switchMap(() => {
        return this.reconnectionService
          .monitorConnection(this.waitingRoomConference)
          .pipe(map(() => WaitingRoomActions.reconnected()));
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private waitingRoomConference: WaitingRoomConferenceService,
    private reconnectionService: ConferenceReconnectionService,
  ) {}
}
