import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { EMPTY, interval, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  skipWhile,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { selectCurrentUser } from '@modules/user/store';
import {
  selectAtLeastOneStudentAndFreemium,
  SessionActions,
} from '../../session/store';
import { RoomActions } from '../../store';
import { FreemiumStatusService } from '../freemium-status.service';
import { LicensingActions } from './actions';
import {
  selectCurrentSession,
  selectIsFreemium,
  selectRemainingMinutes,
} from './selector';

@Injectable()
export class TELicensingEffects {
  private HEARTBEAT_INTERVAL = 60000;

  initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomActions.setSuccess),
      switchMap(() =>
        this.store
          .select(selectCurrentUser)
          .pipe(skipWhile(user => !user.groups)),
      ),
      exhaustMap(user => {
        const isFreemium = user.groups.includes('Limited Room Access');
        const isTEProvider = user.groups.includes('SaaS Providers');
        if (isFreemium) {
          return this.freemiumService.getUserAccount().pipe(
            exhaustMap(result => {
              if (result.length) {
                const account = result[0];
                return this.freemiumService
                  .getCurrentStatus(account.uuid, user.uuid)
                  .pipe(
                    map(res => {
                      const minutesLeft = res.session_minutes_left;
                      return LicensingActions.initialize({
                        isFreemium,
                        isTEProvider,
                        minutesLeft,
                      });
                    }),
                  );
              }
              console.error('TE Licensing error: no account found.');
              return of(LicensingActions.initializeError());
            }),
            catchError(err => {
              console.error('TE Licensing init failed.', err);
              return of(LicensingActions.initializeError());
            }),
          );
        }
        return of(
          LicensingActions.initialize({
            isFreemium,
            isTEProvider,
            minutesLeft: 0,
          }),
        );
      }),
    );
  });

  startSessionHeartbeat$ = createEffect(() => {
    return this.store.pipe(
      select(selectAtLeastOneStudentAndFreemium),
      filter(Boolean),
      switchMap(() => {
        return interval(this.HEARTBEAT_INTERVAL).pipe(
          takeUntil(
            this.store
              .select(selectAtLeastOneStudentAndFreemium)
              .pipe(
                filter(atLeastOneStudentFreemium => !atLeastOneStudentFreemium),
              ),
          ),
          withLatestFrom(this.store.select(selectRemainingMinutes)),
          map(([_, minutesLeft]) => {
            return minutesLeft
              ? LicensingActions.heartbeat()
              : LicensingActions.heartbeatCompleted({ minutesLeft: null });
          }),
        );
      }),
    );
  });

  heartbeat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LicensingActions.heartbeat),
      exhaustMap(() => {
        return this.freemiumService.updateConsumedMinutes().pipe(
          map(res => {
            const minutesLeft = res.account_minutes_left;
            return LicensingActions.heartbeatCompleted({ minutesLeft });
          }),
          catchError(() => {
            console.error('Heartbeat send error, retrying.');
            return of(
              LicensingActions.heartbeatCompleted({ minutesLeft: null }),
            );
          }),
        );
      }),
    );
  });

  heartbeatCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LicensingActions.heartbeatCompleted),
      switchMap(({ minutesLeft }) => {
        if (minutesLeft !== null) {
          return of(LicensingActions.setRemaningMinutes({ minutesLeft }));
        }
        return EMPTY;
      }),
    );
  });

  stopSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LicensingActions.stopSession),
      switchMap(action =>
        of(action).pipe(withLatestFrom(this.store.select(selectIsFreemium))),
      ),
      map(([_, isFreemium]) => {
        if (isFreemium) {
          return SessionActions.kickAllParticipants();
        }
        return LicensingActions.sessionStopped();
      }),
    );
  });
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private freemiumService: FreemiumStatusService,
  ) {}
}
