import { props } from '@ngrx/store';
import { AdmissionInfo, User } from '../user.model';
import { createAction } from '@common/utils';

export interface UserSetPayload {
  isAuthenticated: boolean;
  user: User;
}

const setId = createAction('/auth/setId', props<{ id: string }>());
const set = createAction('/auth/set', props<UserSetPayload>());
const remove = createAction('/auth/remove');
const setAdmissionInfo = createAction(
  '/auth/setAdmissionInfo',
  props<AdmissionInfo>(),
);

export const UserActions = {
  set,
  setId,
  remove,
  setAdmissionInfo,
};
