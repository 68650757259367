import { overlaysStore } from '@app/stores/overlays.store';
import { tokboxRecord } from '@app/stores/tokbox-record.store';
import { documentationNotes } from '@app/stores/documentation-notes.store';

import { reducer as gameSceneBuilder } from '@app/stores/game-scenes.store';
import { reducer as firebaseReducer } from '@common/firebase/store';
import { reducer as mediaReducer } from '@common/media/store';
import { reducer as appReducer } from '@room/app/store';
import { reducer as profileMenuReducer } from '@room/pl-profile-menu/store';
import { reducer as teamWriteReducer } from '../modules/team-write/store/team-write.reducer';
import { reducer as goFishReducer } from '../modules/room/pl-activity/game/go-fish/store/go-fish.reducer';
import { sessionReducer } from '../modules/room/session/store';
import { reducer as roomReducer } from '../modules/room/store';
import { reducer as libraryReducer } from '@library/store';

export const appStore = {
  documentationNotes,
  gameSceneBuilder,
  overlaysStore,
  tokboxRecord,
  app: appReducer,
  firebase: firebaseReducer,
  profileMenu: profileMenuReducer,
  media: mediaReducer,
  teamWrite: teamWriteReducer,
  goFishGame: goFishReducer,
  session: sessionReducer,
  room: roomReducer,
  library: libraryReducer,
};
