import { ErrorHandler, Injectable } from '@angular/core';

import { SentryLoggerService } from './sentry-logger.service';
import { User } from '@root/src/app/modules/user/user.model';

export function initLogger() {
  SentryLoggerService.init();
}

export function createErrorHandler(): ErrorHandler {
  return SentryLoggerService.createErrorHandler();
}

export interface LoggerEventMeta {
  level?: 'debug' | 'fatal' | 'error' | 'warning' | 'log' | 'info';
  tags?: Record<string, string | boolean | number>;
}

@Injectable({ providedIn: 'root', useClass: SentryLoggerService })
export abstract class LoggerService {
  public abstract identifyUser(user: User, meta: { isHost: boolean }): void;

  public abstract logEvent(message: string, meta?: LoggerEventMeta): void;

  public abstract error(err: any, meta?: LoggerEventMeta): void;
}
