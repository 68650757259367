import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { SessionState, Participant, ParticipantStatus } from './session.model';
import * as SessionActions from './session.actions';

export const adapter = createEntityAdapter<Participant>();

export const initialState: SessionState = adapter.getInitialState({
  initialized: false,
});

export const sessionReducer = createReducer(
  initialState,
  on(
    SessionActions.initObserver,
    SessionActions.addLocal,
    (state, { type, participant }) => {
      const isInitObserver = type === SessionActions.initObserver.type;

      const newState = adapter.addOne(participant, state);

      newState.localId = participant.id;
      newState.isObserverInteractionPending = isInitObserver;

      return newState;
    },
  ),
  on(
    SessionActions.addWaitingSuccess,
    SessionActions.addedRemotely,
    (state, { participant }) => {
      return adapter.addOne(participant, state);
    },
  ),
  on(SessionActions.setLocalOffline, (state, { offline }) => {
    if (!state.localId) {
      return state;
    }
    const id = state.localId;
    return adapter.updateOne({ id, changes: { offline } }, state);
  }),
  on(SessionActions.updatedRemotely, (state, { id, participant }) => {
    const newState = adapter.updateOne({ id, changes: participant }, state);

    const entity = newState.entities[id];

    if (entity.offline && !participant.offline) {
      entity.offline = null;
      entity.offlineAt = null;
    }

    return newState;
  }),
  on(
    SessionActions.removedRemotely,
    SessionActions.kickSuccess,
    (state, { id }) => {
      return adapter.removeOne(id, state);
    },
  ),
  on(SessionActions.setIsViewingPageSuccess, (state, { id, isViewingPage }) => {
    return adapter.updateOne({ id, changes: { isViewingPage } }, state);
  }),
  on(SessionActions.admitSuccess, (state, { id }) => {
    const status = ParticipantStatus.entering;
    return adapter.updateOne({ id, changes: { status } }, state);
  }),
  on(SessionActions.triggerObserverInteraction, state => {
    return {
      ...state,
      isObserverInteractionPending: false,
    };
  }),
);
