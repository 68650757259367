import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VisibilityService {
  static get currentValue() {
    return document?.visibilityState ?? 'hidden';
  }

  getValue() {
    return fromEvent(document, 'visibilitychange').pipe(
      startWith(VisibilityService.currentValue),
      map(() => document.visibilityState),
    );
  }
}
