import { ENV } from '../@types/internal';

// This configuration is used by staging-room.presence.com & k8s
export const environment: ENV = {
  env_key: 'staging',
  production: false,
  debug: false,
  app_name: 'room',
  cookie_domain: process.env.COOKIE_DOMAIN || 'presence.com',
  pendo_key: '',
  sentry_key:
    process.env.SENTRY_DSN ??
    'https://a275cf49dbf948e0959808f246109f19@o7007.ingest.sentry.io/6480814',
  sentry_replay_enabled: false,
  speed_of_me: {
    key: 'SOM60d26a7e8aa1b',
  },
  config_cat_sdk:
    process.env.CONFIG_CAT_SDK ||
    'DgLbCHEjckGEDa0uS7QeUQ/csBQVeuXPky3txDOpyng_w',
  siteshare_url: 'https://staging-siteshare.presencelearning.com',
  emulate_firebase: process.env.EMULATE_FIREBASE === 'true',
  git_sha: process.env.GIT_SHA,
  login_url: 'https://staging-login.presence.com',
  apps: {
    auth: {
      url: 'https://staging-api.presence.com/login',
    },
    apiWorkplace: {
      url: 'https://staging-api.presence.com/workplace',
    },
    apollo: {
      url: 'https://staging-api.presence.com/workplace/graphql/v1/',
    },
    eduClients: {
      url: 'https://staging-apps.presence.com/c',
    },
    flutterApp: {
      url: 'https://staging-flutterapp.presence.com',
    },
    platform: {
      url: 'https://staging-api.presence.com/platform',
    },
    library: {
      url: 'https://staging-room.presence.com/library',
    },
    apiEssentials: {
      url: 'https://staging-api.presence.com/essentials',
    },
    therapy: {
      url: 'https://staging-kanga.presence.com',
    },
    learnupon: {
      url: 'presencedev.learnuponus.com',
    },
    apiMizar: {
      url: 'https://staging-api.presence.com/mizar',
    },
    chiron: {
      url: 'https://staging-chiron.presence.com',
    },
  },
  room: {
    inactiveSeconds: 3600,
  },
  feature_flags: {
    sharedCursorTokbox: 1,
  },
  zendesk_key: '6af957a2-f24e-4df2-b799-fef1d66da8c0',
  clickup: {
    key: 'pk_108077245_H4U9EBR8DRW0H82KJUCX2B0FTDAAZC1Q',
    team_id: '2399670',
    url: 'https://api.clickup.com/api/v2',
    lists: [
      {
        id: '901703291193',
        name: 'Staging',
        custom_fields: [
          {
            id: '2b8352c6-cf27-46d4-8675-b571ab5d3975',
            name: 'Field 1',
          },
          {
            id: '412ad1df-bee2-4b8e-bd46-3fc2c1cff15b',
            name: 'Field 2',
          },
          {
            id: 'c97f7f73-c8d7-4b4c-8940-4a9fbefa637a',
            name: 'Field 3',
          },
        ],
      },
    ],
  },
};
