import { Injectable } from '@angular/core';
import moment from 'moment';

import { User } from '@app/modules/user/user.model';
import { PLRecordRoomService } from '@root/src/app/common/services/pl-records';
import { selectAuth } from '@root/src/app/modules/user/store';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

export interface ClientAppointment {
  uuid: string;
  appointment: {
    uuid: string;
    start: string;
    end: string;
  };
  first_name: string;
  last_name: string;
  record: {
    signed: boolean;
  };
}

@Injectable({ providedIn: 'root' })
export class ClientAppointmentsService {
  currentUser: User;
  appointmentOptions: {
    value: any;
    label: string;
  }[];

  appointmentsMap: Record<string, ClientAppointment> = {};
  appointmentOptions$ = new BehaviorSubject<{ value: any; label: string }[]>(
    [],
  );

  constructor(
    private store: Store<AppState>,
    private plRecordRoom: PLRecordRoomService,
  ) {
    this.store.select(selectAuth).subscribe(({ isAuthenticated, user }) => {
      if (isAuthenticated) {
        this.currentUser = user;
      }
    });
    this.subscribeClientAppointments();
  }
  subscribeClientAppointments() {
    return this.plRecordRoom
      .getClientAppointmentsData()
      .subscribe((results: any) => {
        if (!results.clients?.length || this.currentUser.xProvider === null) {
          return;
        }
        this.appointmentOptions = this.parseAppointmentOptions(results.clients);
        this.appointmentsMap = {};
        results.clients.forEach(appt => {
          this.appointmentsMap[`${appt.uuid}${appt.appointment.uuid}`] = appt;
        });
        this.appointmentOptions$.next(this.appointmentOptions);
      });
  }

  parseAppointmentOptions(clientAppointments: ClientAppointment[]) {
    return (
      clientAppointments
        // filter out signed appointments
        .filter(appt => !appt.record.signed)
        .map(appt => {
          const startTime = this.formatTime(appt.appointment.start);
          const endTime = this.formatTime(appt.appointment.end);
          const sortTime = this.formatTime(appt.appointment.start, true);
          return {
            value: `${appt.uuid}${appt.appointment.uuid}`,
            label: `${appt.first_name} ${appt.last_name} ${startTime}-${endTime}`,
            nameLabel: `${appt.first_name} ${appt.last_name}`,
            timeLabel: `${startTime}-${endTime}`,
            xSortString: `${sortTime} ${appt.first_name} ${appt.last_name}`,
          };
        })
        .sort((a, b) => a.xSortString.localeCompare(b.xSortString))
    );
  }

  formatTime(time: string, military = false) {
    return moment
      .tz(time, 'YYYY-MM-DD HH:mm:ssZ', this.currentUser.xProvider.timezone)
      .format(military ? 'HH:mm' : 'h:mm A');
  }

  getAppointmentById(id: string) {
    return this.appointmentsMap[id];
  }
}
