import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  PlSnackbarWrapperComponent,
  PlSnackbarWrapperData,
} from '@library/common/components/pl-snackbar/pl-snackbar-wrapper/pl-snackbar-wrapper.component';

interface NotificationData {
  type?: 'default' | 'error';
  text: string;
}

const DEFAULT_DURATION = 4000;

@Injectable({
  providedIn: 'root',
})
export class Notifications {
  constructor(private snackBar: MatSnackBar) {}

  show(
    notification: NotificationData,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
  ) {
    this.snackBar.open(notification.text, null, {
      duration: DEFAULT_DURATION,
      panelClass: `snack-bar-is-${notification.type || 'default'}`,
      verticalPosition,
      horizontalPosition,
    });
  }

  /**
   * Displays a snackbar with the provided data and configuration options.
   * @param data - The data to be displayed in the snackbar.
   * @param config - Optional configuration options for the snackbar.
   * @returns A reference to the opened snackbar.
   */
  showPLSnackBar(
    data: PlSnackbarWrapperData,
    config?: {
      duration?: number;
      verticalPosition?: MatSnackBarVerticalPosition;
      horizontalPosition?: MatSnackBarHorizontalPosition;
    },
  ): MatSnackBarRef<PlSnackbarWrapperComponent> {
    return this.snackBar.openFromComponent<
      PlSnackbarWrapperComponent,
      PlSnackbarWrapperData
    >(PlSnackbarWrapperComponent, {
      data,
      duration: config?.duration ?? DEFAULT_DURATION,
      verticalPosition: config?.verticalPosition ?? 'top',
      horizontalPosition: config?.horizontalPosition ?? 'center',
      panelClass: 'pl-snackbar-wrapper',
    });
  }
}
