import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LeavingPageService {
  onLeavingPage() {
    const evName = window.roomGlobal.isIPadSafari ? 'pagehide' : 'beforeunload';
    return fromEvent(window, evName);
  }
}
