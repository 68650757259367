import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigCatFeatureFlagsService } from './configcat-feature-flags.service';
import { FeatureFlag } from './feature-flags.constant';
import { User } from '@root/src/app/modules/user/user.model';

@Injectable({ providedIn: 'root', useClass: ConfigCatFeatureFlagsService })
export abstract class FeatureFlagsService {
  public abstract identifyUser(user: User, roomName: string): void;
  public abstract isFeatureEnabled(flagName: FeatureFlag): Observable<boolean>;
}
