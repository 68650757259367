import { AppState } from '@app/store';
import { mediaEntityAdapter, mediaSelectId } from './media.reducer';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { DeviceInfo } from './media.model';

export const selectMediaFeature = (state: AppState) => state.media;

const { selectAll, selectEntities } =
  mediaEntityAdapter.getSelectors(selectMediaFeature);

export const selectIsMediaLoading = createSelector(
  selectMediaFeature,
  media => media.status === 'loading',
);

export const selectDevices = selectAll;

export const selectDevicesMap = selectEntities;

export const selectAudioDevices = createSelector(selectDevices, devices =>
  devices.filter(d => d.kind === 'audioinput'),
);

export const selectAudioDevice = (deviceId: string | boolean) =>
  createSelector(selectDevicesMap, devices =>
    findDevice('audioinput', deviceId, devices),
  );

export const selectVideoDevices = createSelector(selectDevices, devices =>
  devices.filter(d => d.kind === 'videoinput'),
);

export const selectVideoDevice = (deviceId: string | boolean) =>
  createSelector(selectDevicesMap, devices =>
    findDevice('videoinput', deviceId, devices),
  );

function findDevice(
  kind: MediaDeviceKind,
  deviceId: string | boolean,
  devices: Dictionary<DeviceInfo>,
) {
  if (deviceId === false) {
    return false;
  }

  if (typeof deviceId === 'string' && deviceId.length) {
    return devices[mediaSelectId(kind, deviceId)];
  }

  return Object.values(devices).some(d => d.kind === kind);
}
