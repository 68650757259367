import { createSelector } from '@ngrx/store';
import { AppState } from '@root/src/app/store';

export const selectAuth = (store: AppState) => store.auth;

export const selectUserId = createSelector(selectAuth, state => {
  return state.userId;
});

export const selectCurrentUser = createSelector(selectAuth, state => {
  return state?.user;
});

export const selectCurrentUserEmail = createSelector(
  selectCurrentUser,
  user => user?.email,
);

export const selectCurrentUserFirstNameLastInitial = createSelector(
  selectCurrentUser,
  user => `${user?.first_name} ${user?.last_name?.charAt(0)}`,
);

export const selectCurrentUserInitials = createSelector(
  selectCurrentUser,
  user => `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`,
);

export const selectCurrentUserFullName = createSelector(
  selectCurrentUser,
  user => `${user?.first_name} ${user?.last_name}`.trim(),
);

export const selectCurrentUserUsername = createSelector(
  selectCurrentUser,
  user => user?.username,
);

export const selectCurrentUserImage = createSelector(
  selectCurrentUser,
  user => user?.profile_image_url,
);

export const selectIsProvider = createSelector(selectCurrentUser, user => {
  return (
    user &&
    user.groups &&
    (user.groups.indexOf('Provider') > -1 ||
      user.groups.indexOf('Service & Support') > -1 ||
      user.groups.indexOf('School Staff Providers') > -1 ||
      user.groups.indexOf('Private Practice') > -1)
  );
});

export const selectIsObserver = createSelector(selectCurrentUser, user => {
  return user && user.groups && user.groups.indexOf('Observer') > -1;
});

export const selectIsStudent = createSelector(selectCurrentUser, user => {
  return user && user.groups && user.groups.indexOf('Student') > -1;
});

export const selectAuthGroups = createSelector(
  selectAuth,
  state => state.groups,
);

export const selectIsSaasProvider = createSelector(
  selectAuthGroups,
  groups => groups.SaaS,
);

export const selectIsLimitedSaasAccess = createSelector(
  selectCurrentUser,
  user => user?.groups?.includes('Limited SaaS Access'),
);

export const selectIsViaUser = createSelector(selectCurrentUser, user =>
  user?.groups?.includes('via'),
);

export const selectIsHijackedUser = createSelector(
  selectCurrentUser,
  user => user?.userStatus?.plru !== user?.userStatus?.sub,
);

export const selectIsContentPublisher = createSelector(
  selectCurrentUser,
  user => user?.groups?.includes('Content Publishers'),
);

export const selectIsKangaUser = createSelector(
  selectCurrentUser,
  user =>
    user?.groups?.includes('SaaS Providers') ||
    user?.groups?.includes('School Staff Admins'),
);

export const selectIsKangaAdmin = createSelector(selectCurrentUser, user =>
  user?.groups?.includes('School Staff Admins'),
);

export const selectIsAssessmentUser = createSelector(
  selectCurrentUser,
  user =>
    user?.groups?.includes('Therapist') ||
    user?.groups?.includes('Administrator') ||
    user?.groups?.includes('Assessment Users'),
);

export const selectIsReferralManager = createSelector(
  selectCurrentUser,
  user =>
    user?.groups?.includes('Clinical Account Manager') ||
    user?.groups?.includes('Service & Support') ||
    user?.is_superuser,
);

export const selectIsClinicianOrExternalProvider = createSelector(
  selectCurrentUser,
  user =>
    user?.groups?.includes('Therapist') ||
    user?.groups?.includes('Administrator') ||
    user?.groups?.includes('School Staff Providers') ||
    user?.groups?.includes('Private Practice') ||
    user?.groups?.includes('SaaS Providers'),
);
