<div class="pl-confirm">
  <header class="dialog-header">
    <h2 class="dialog-header__title heading-2">
      {{ data.header }}
    </h2>

    <button mat-icon-button mat-dialog-close class="dialog-header__close-btn">
      <mat-icon>close</mat-icon>
    </button>
  </header>

  <mat-dialog-content class="dialog-content">
    <div class="dialog-content__text" [innerHTML]="data.content"></div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <ng-container *ngIf="!data.reverseButtons; else reversedButtons">
      <button
        mat-stroked-button
        mat-dialog-close
        color="primary"
        data-testid="cancel-button">
        Cancel
      </button>

      <button
        mat-raised-button
        mat-dialog-close
        cdkFocusInitial
        color="primary"
        (click)="handleConfirm()"
        data-testid="confirm-button">
        {{ data.confirmLabel || 'Confirm' }}
      </button>
    </ng-container>
    <ng-template #reversedButtons>
      <button
        mat-stroked-button
        mat-dialog-close
        color="primary"
        (click)="handleConfirm()"
        data-testid="confirm-button">
        {{ data.confirmLabel || 'Confirm' }}
      </button>

      <button
        mat-raised-button
        mat-dialog-close
        cdkFocusInitial
        color="primary"
        data-testid="cancel-button">
        Cancel
      </button>
    </ng-template>
  </mat-dialog-actions>
</div>
