import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { CurrentUserService } from '../current-user.service';
import {
  FeatureFlagsService,
  FeatureFlag,
} from '@common/services/feature-flags';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InactivityLogoutService {
  FIVE_SECONDS: number;
  ONE_MINUTE: number;
  beforeExpTimer: any;
  afterExpTimer: any;
  lastMinuteTimerOn: boolean;
  lastActiveTimestamp: number;
  tokenDurationSecs: number;
  tokenDurationMillis: number;
  tokenDurationMins: number;
  initialized: boolean;

  constructor(
    private _authenticationService: AuthenticationService,
    private _currentUserService: CurrentUserService,
    private _featureFlagsService: FeatureFlagsService,
  ) {
    // Service globals
    this.FIVE_SECONDS = 5000;
    this.ONE_MINUTE = 60000;
    this.lastMinuteTimerOn = false;
    this.lastActiveTimestamp = Date.now();
    this.initialized = false;
  }

  //  Pull, parse, & decode jwt. Determine number of minutes until expiration
  private setTokenDuration() {
    if (this._currentUserService && this._currentUserService.jwt) {
      const jwtArray = this._currentUserService.jwt.split('.');
      const jwtPayload = jwtArray[1];
      const jwtPayloadDecoded = JSON.parse(atob(jwtPayload));

      this.tokenDurationSecs = Math.max(
        jwtPayloadDecoded.exp - jwtPayloadDecoded.iat,
        60,
      );
      this.tokenDurationMillis = this.tokenDurationSecs * 1000;
      this.tokenDurationMins = Math.floor(this.tokenDurationSecs / 60);
    } else {
      this.tokenDurationSecs = 20000;
      this.tokenDurationMillis = this.tokenDurationSecs * 1000;
      this.tokenDurationMins = Math.floor(this.tokenDurationSecs / 60);
    }
  }

  // Listen on keyboard and mouse movements.
  // Update lastActive
  // If active in final minute before logout, immediately get new jwt
  private setupActivityListeners() {
    $(document).mousemove(
      _.throttle(() => {
        this.lastActiveTimestamp = Date.now();
        if (this.lastMinuteTimerOn) {
          this.lastMinuteTimerOn = false;
          this._authenticationService.authenticate(0).then(() => {
            this.start();
          });
        }
      }, this.FIVE_SECONDS),
    );

    $(document).keypress(
      _.throttle(() => {
        this.lastActiveTimestamp = Date.now();
        if (this.lastMinuteTimerOn) {
          this.lastMinuteTimerOn = false;
          this._authenticationService.authenticate(0).then(() => {
            this.start();
          });
        }
      }, this.FIVE_SECONDS),
    );
  }

  /// Before Expired ///
  private stopBeforeExpTimer() {
    clearInterval(this.beforeExpTimer);
  }

  private startBeforeExpTimer() {
    this.stopBeforeExpTimer();

    this.beforeExpTimer = setInterval(() => {
      const lastActiveMins = Math.floor(
        (Date.now() - this.lastActiveTimestamp) / 1000 / 60,
      );

      if (lastActiveMins < this.tokenDurationMins - 1) {
        this._authenticationService.authenticate(lastActiveMins).then(() => {
          this.start();
        });
      } else {
        // only 1 minute left
        this.lastMinuteTimerOn = true;
      }
    }, this.tokenDurationMillis - this.ONE_MINUTE);
  }

  /// After expired ///
  stopAfterExpTimer() {
    clearInterval(this.afterExpTimer);
  }

  startAfterExpTimer() {
    this.stopAfterExpTimer();

    this.afterExpTimer = setInterval(() => {
      const lastActiveMins = Math.floor(
        (Date.now() - this.lastActiveTimestamp) / 1000 / 60,
      );

      // should 401 and log user out
      this._authenticationService.authenticate(lastActiveMins);
    }, this.tokenDurationMillis + this.FIVE_SECONDS);
  }

  // MAIN FUNCTION. Use inactivityLogoutService.start() to inject autologout
  // into your app
  start() {
    // Check if feature flag is enabled
    // If not, initialize the service
    this._featureFlagsService
      .isFeatureEnabled(FeatureFlag.INACTIVITY_AUTOLOGOUT_BYPASS)
      .pipe(first())
      .subscribe(enabled => {
        if (!enabled) {
          if (this.initialized) {
            return;
          }
          this.setTokenDuration();

          this.setupActivityListeners();
          this.startBeforeExpTimer();
          this.startAfterExpTimer();
        }
      });
  }
}
