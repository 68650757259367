export interface TeamWriteState {
  key: string;
  isReady: boolean;
  isDocLoaded: boolean;
  saveDoc: boolean;
  resetDoc: boolean;
  newDoc: boolean;
  loadingDoc: boolean;
  deletingDoc: boolean;
  trashingDoc: boolean;
  restoringDoc: boolean;
}

export const initialState: TeamWriteState = {
  key: '',
  isReady: false,
  isDocLoaded: false,
  saveDoc: false,
  resetDoc: false,
  newDoc: false,
  loadingDoc: false,
  deletingDoc: false,
  trashingDoc: false,
  restoringDoc: false,
};
