import { createReducer, on } from '@ngrx/store';
import { RoomState } from './room.model';
import { RoomActions } from './room.actions';

const initialState: RoomState = {
  status: 'loading',
};

export const reducer = createReducer(
  initialState,
  on(RoomActions.setLoading, () => {
    return {
      status: 'loading' as RoomState['status'],
    };
  }),
  on(RoomActions.setData, (state, { data }) => {
    return {
      data,
      status: 'loading' as RoomState['status'],
    };
  }),
  on(RoomActions.setSuccess, state => {
    return {
      data: state.data,
      status: 'success' as RoomState['status'],
    };
  }),
  on(RoomActions.setError, (state, { error }) => {
    return {
      error,
      status: 'loading',
      ...state,
    };
  }),
);
