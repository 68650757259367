import { Injectable } from '@angular/core';
import { catchError, map, take, tap } from 'rxjs/operators';
import { environment } from '@root/src/environments/environment';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';
import { PLGraphQLService } from '@common/services/pl-graph-ql';
import { AuthenticatedUser } from './user-models.service';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
/**
 * Responsible for authentication with the Auth backend service.
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  onLogoutCallbacks: any[];
  afterLogoutCallbacks: any[];

  private get authUrl() {
    return environment.apps.auth.url;
  }
  private get loginUrl() {
    return `${environment.login_url}/login/`;
  }
  private get logoutUrl() {
    return `${environment.login_url}/logout/`;
  }
  private get clientTokenUrl() {
    return `${this.authUrl}/api/v2/users/generate_client_token/`;
  }

  constructor(
    private plHttp: PLHttpService,
    private plGraphql: PLGraphQLService,
    private plUrls: PLUrlsService,
    private router: Router,
  ) {
    this.onLogoutCallbacks = [];
    this.afterLogoutCallbacks = [];
  }

  getUserImage(uuid: string) {
    const url = `${this.plUrls.urls.apiMizarFE}/v1/images/${uuid}/latest/presigned-url`;
    return this.plHttp
      .get('', {}, url, {
        suppressError: true,
      })
      .pipe(
        map(({ image_presigned_url }: any) => image_presigned_url),
        catchError(() => of(null)),
      );
  }

  /**
   * [authenticate description]
   * @return {Promise(authUser)} promise for the authentication user object
   * from the Auth backend service.
   */
  authenticate(lastActive = 0, noRedirect = false) {
    let user: AuthenticatedUser;
    let jwtToken: string;
    return this.plHttp
      .get('status', { lastActive, withCredentials: true }, null, {
        suppressError: true,
      })
      .toPromise()
      .then(res => {
        const userData = res.user;
        // unpack jwt token for hijack information
        jwtToken = res.token;
        if (jwtToken && window && window.atob) {
          const userStatusFromJwtToken = JSON.parse(
            window.atob(jwtToken.split('.')[1]),
          );
          userData.userStatus = userStatusFromJwtToken;
        }
        user = new AuthenticatedUser(userData);
        return this.plHttp
          .get('', {}, `${this.plUrls.urls.providers}${user.uuid}/`, {
            suppressError: true,
          })
          .toPromise();
      })
      .then(
        res => {
          user.xProvider = res;
          return this.getCurrentUserQuery();
        },
        err => {
          if (err?.status === 401 && !noRedirect) {
            this.login();
            return;
          }
          const isTechCheck = this.router.url
            .toLowerCase()
            .includes('pl/setup');

          return this.getCurrentUserQuery({
            suppressError: isTechCheck,
            retryOnAuthenticationFailure: !isTechCheck,
          });
        },
      )
      .then(
        res => {
          return {
            user,
            token: jwtToken,
          };
        },
        err => {
          if (err?.status === 401 && !noRedirect) {
            this.login();
          }
        },
      );
  }

  getStudentToken(name, browserId): Observable<string> {
    return this.plHttp
      .save(
        null,
        { client_name: name, browser_id: browserId },
        this.clientTokenUrl,
      )
      .pipe(
        tap({
          error: ([data, status, headers, config]) => {
            console.log('ERROR: ' + data);
            console.log('ERROR: ' + status);
            console.log('ERROR: ' + headers);
            console.log('ERROR: ' + config);
          },
        }),
        map((response: any) => response.token),
      );
  }

  /**
   * Redirects the user to the login page.
   *
   * @return {301} redirects immediatly.
   */
  login() {
    window.location.href = `${this.loginUrl}?next=${window.location.href}`;
  }

  /**
   * Subscribe to logout events.
   *
   * Called with one parameter, the logout context (See logout)
   *
   * @param  {Function} callback
   */
  onLogout(callback) {
    this.onLogoutCallbacks.push(callback);
  }

  /**
   * Subscribe to after logout events.
   *
   * Fired after logout callbacks have been fired.
   *
   * @param  {Function} callback [description]
   * @return {[type]}            [description]
   */
  afterLogout(callback) {
    this.afterLogoutCallbacks.push(callback);
  }

  /**
   * Redirects the browser to the login screen.
   *
   * @return {[type]} [description]
   */
  redirectToAuth() {
    window.location.href = this.logoutUrl;
  }

  /**
   * Logs the user out of the application.
   *
   * Goes through all registered onLogout callbacks and fires them
   * (and waits for them to complete)
   * Then redirects the user to the auth.logoutUrl
   *
   * @return {promise} which is pointless because of the redirect.
   */
  logout() {
    console.log(
      'logout in authenticationService, onLogoutCallbacks: ',
      this.onLogoutCallbacks,
    );
    const promises = [];

    // first take care of notifying everyone who has subscribed.
    // NOTE: These fire immediatly, there is no waiting until each
    // callback finishes. Your callback should assume the current
    // user is already logged out from the backend.
    this.onLogoutCallbacks.forEach(callback => {
      const result = callback();
      if (result && result.then) {
        promises.push(result);
      }
    });

    const allPromise = Promise.all(promises);
    allPromise.finally(() => {
      const afterPromises = [];
      this.afterLogoutCallbacks.forEach(callback => {
        const result = callback();
        if (result && result.then) {
          afterPromises.push(result);
        }
      });
      return Promise.all(afterPromises);
    });
    return allPromise;
  }

  private getCurrentUserQuery(options = {}) {
    return this.plGraphql
      .query(
        `query {
                    currentUser {
                        enabledUiFlags
                    }
                }
                `,
        {},
        options,
      )
      .pipe(take(1))
      .toPromise();
  }
}
