import { Injectable } from '@angular/core';

import { CurrentUserService } from '@common/models/current-user.service';
import {
  LocalStorageKey,
  LocalStorageService,
} from '@common/services/LocalStorageService';

@Injectable()
export class LoginMonitorService {
  constructor(
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService,
  ) {}

  start() {
    const loginTime = '' + Date.now();
    this.localStorageService.set(LocalStorageKey.LOGIN_TIME, loginTime);
    setInterval(() => {
      const timeCheck = this.localStorageService.get(
        LocalStorageKey.LOGIN_TIME,
      );
      if (timeCheck !== loginTime) {
        console.log('Double login detected, logging out');
        this.currentUserService.logout();
      }
    }, 1000);
  }
}
