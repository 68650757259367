<div class="edit-queue-details">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{ data.title || 'Edit queue details' }}</h2>
    <button mat-icon-button mat-dialog-close aria-label="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="content">
    <form [formGroup]="queueForm">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Queue name</mat-label>
        <input matInput formControlName="name" data-testid="queue-name-input" />
      </mat-form-field>
      <mat-form-field
        *ngIf="data.showDescription !== undefined ? data.showDescription : true"
        appearance="outline"
        color="accent">
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [mat-dialog-close]="data"
      [disabled]="!queueForm.valid"
      data-testid="queue-save-button">
      {{ data.saveButtonLabel || 'Update details' }}
    </button>
  </mat-dialog-actions>
</div>
