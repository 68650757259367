import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { selectAuth } from '@root/src/app/modules/user/store';
import { AppState } from '@root/src/app/store';
import { PLUrlsService } from '@common/services/pl-http';

import { PLHijackHelperService } from '../../services/pl-hijack.service';

@Component({
  selector: 'pl-hijack-drawer',
  templateUrl: 'pl-hijack-drawer.component.html',
  styleUrls: ['./pl-hijack-drawer.component.less'],
})
export class PLHijackDrawerComponent implements OnInit, OnDestroy {
  username = '';

  private userSub: Subscription;

  constructor(
    private hijackHelper: PLHijackHelperService,
    private plUrls: PLUrlsService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.userSub = this.store
      .select(selectAuth)
      .subscribe(({ user, isAuthenticated }) => {
        if (!isAuthenticated) {
          return;
        }
        this.username = user.username;
      });
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  onClickReleaseUser() {
    window.location.href = this.plUrls.urls.releaseLogin;
  }

  onClickTab() {
    const value = !this.isExpanded;
    this.hijackHelper.setDrawerExpand(value);
  }

  get isExpanded() {
    return this.hijackHelper.isDrawerExpanded;
  }
}
