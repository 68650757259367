import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';

import { VisibilityService } from '../../services';
import * as WindowActions from '../window.actions';

@Injectable({ providedIn: 'root' })
export class WindowVisibilityStateEffects {
  visibilityChanged$ = createEffect(() => {
    return this.visibilityService.getValue().pipe(
      map(visibilityState =>
        WindowActions.visibilityChanged({
          visibilityState,
        }),
      ),
    );
  });

  constructor(
    private visibilityService: VisibilityService,
  ) {}
}
