import { AfterViewInit, Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IPadSupportService } from '@common/services';
import { PLWindowWarningDialogComponent } from './window-warning-dialog.component';

@Component({
  selector: 'pl-window-warning',
  template: '',
})
export class PlWindowWarningComponent implements AfterViewInit {
  private windowWarningRef: MatDialogRef<PLWindowWarningDialogComponent>;
  @HostListener('window:resize', ['$event']) onResize() {
    this.checkWindowSize();
  }

  constructor(
    private ipadService: IPadSupportService,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    this.checkWindowSize();
  }

  private checkWindowSize() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const isIpad = this.ipadService.isIPad();
    if (isIpad && windowWidth < windowHeight) {
      if (this.windowWarningRef) {
        return;
      }
      this.windowWarningRef = this.dialog.open(PLWindowWarningDialogComponent, {
        disableClose: true,
        width: '50vw',
        autoFocus: false,
      });
    } else if (this.windowWarningRef) {
      this.windowWarningRef.close();
      this.windowWarningRef = null;
    }
  }
}
