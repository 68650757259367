import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  header: string;
  content: string;
  cancelLabel?: string;
  confirmLabel?: string;
  reverseButtons?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

@Component({
  selector: 'pl-confirm',
  templateUrl: 'pl-confirm.component.html',
  styleUrls: ['./pl-confirm.component.less'],
})
export class PLConfirmComponent {
  constructor(
    private dialogRef: MatDialogRef<PLConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data?.onCancel) {
        this.data?.onCancel();
      }
    });
  }

  handleConfirm() {
    this.data?.onConfirm();
  }
}
