import { Injectable } from '@angular/core';
import { PLGuidService } from './GuidService';
import {
  LocalStorageKey,
  LocalStorageService,
} from '@common/services/LocalStorageService';

@Injectable({ providedIn: 'root' })
export class BrowserIdService {
  readonly browserId: string;

  constructor(
    private guidService: PLGuidService,
    private localStorageService: LocalStorageService,
  ) {
    const existingId = this.localStorageService.get(LocalStorageKey.BROWSER_ID);
    this.browserId = existingId ?? this.createBrowserId();
  }

  private createBrowserId() {
    const newId = this.guidService.generateUUID();
    this.localStorageService.set(LocalStorageKey.BROWSER_ID, newId);
    return newId;
  }
}
