import { Injectable } from '@angular/core';
import { SentryLoggerService } from '../services/logger/sentry-logger.service';
import {
  ConferenceProvider,
  JoinConfig,
  ConferenceStatus,
  FilterVideoMode,
} from './conference-provider';
import {
  TokboxConfig,
  TokboxConferenceProvider,
} from './toxbox-conference-provider';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  of,
  throwError,
  timer,
} from 'rxjs';
import {
  filter,
  mergeMap,
  retryWhen,
  switchMap,
  take,
  timeout,
} from 'rxjs/operators';
import { LoggerService } from '../services/logger/logger.service';

export interface ConferenceConfig {
  name?: string;
  tokbox?: TokboxConfig;
}
@Injectable({ providedIn: 'root' })
export class ConferenceService {
  private conferenceSubject = new BehaviorSubject<ConferenceProvider>(null);
  public conference$: Observable<ConferenceProvider>;

  public config: ConferenceConfig;

  get conference() {
    return this.conferenceSubject.getValue();
  }

  get tokboxConference() {
    return this.conference as TokboxConferenceProvider;
  }

  get tokboxConference$() {
    return this.conference$ as Observable<TokboxConferenceProvider>;
  }

  get name() {
    return this.config.name ?? 'Conference';
  }

  constructor(private logger: LoggerService) {
    this.conference$ = this.conferenceSubject.asObservable();
  }

  setConfig(config: ConferenceConfig) {
    this.config = config;
    const { tokbox } = config;
    if (!tokbox) {
      throw new Error('ConferenceService needs the provider credentials');
    }

    this.setConference(new TokboxConferenceProvider(tokbox, this.logger));
  }

  init() {
    return this.conference.init().pipe(
      retryWhen(errors => {
        return errors.pipe(
          mergeMap((err, index) => {
            const attempt = index + 1;
            if (attempt > 5) {
              this.logger.logEvent('Tokbox - Max reconnect attempts reached', {
                level: 'error',
                tags: {
                  errorMessage: err,
                },
              });
              return throwError(err);
            }

            console.log(
              `Attempt ${attempt} - Retrying to connect ${this.name} after error`,
              err,
            );
            return timer(1000);
          }),
        );
      }),
    );
  }

  getProviderId(id: string) {
    const stream = this.conference.getStream(id);

    return stream && stream.providerId;
  }

  join(id: string, config?: JoinConfig) {
    return this.waitForConnection().pipe(
      mergeMap(() => this.conference.join(id, config)),
    );
  }

  leave(id: string) {
    return this.conference.leave(id);
  }

  getStatus() {
    return this.conference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return of(ConferenceStatus.idle);
        }
        return conference.getStatus();
      }),
    );
  }

  getMediaStream(id: string) {
    return this.conference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return of(null);
        }
        return conference.getMediaStream(id);
      }),
    );
  }

  startScreenshare(id: string) {
    return this.conference.startScreenshare(id);
  }

  stopScreenshare(id: string) {
    return this.conference.stopScreenshare(id);
  }

  setAudioSource(id: string, source: string) {
    return this.conference.setAudioSource(id, source);
  }

  setVideoSource(id: string, source: string) {
    return this.conference.setVideoSource(id, source);
  }

  setFilterVideoMode(id: string, mode: FilterVideoMode) {
    return this.conference.setVideoFiltering(id, mode);
  }

  mute(id: string) {
    this.conference.mute(id);
  }

  unmute(id: string) {
    this.conference.unmute(id);
  }

  show(id: string) {
    this.conference.show(id);
  }

  hide(id: string) {
    this.conference.hide(id);
  }

  onJoined() {
    return this.conference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return EMPTY;
        }
        return conference.on('joined');
      }),
    );
  }

  onRemoteConnected() {
    return this.conference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return EMPTY;
        }

        return conference.on('remoteConnected');
      }),
    );
  }

  onRemoteDisconnected() {
    return this.conference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return EMPTY;
        }

        return conference.on('remoteDisconnected');
      }),
    );
  }

  command(cmd: string, data?: string) {
    return this.conference.command(cmd, data);
  }

  commandTo(to: string, cmd: string, data?: string) {
    return this.conference.commandTo(to, cmd, data);
  }

  onCommand(cmd: string) {
    return this.conference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return EMPTY;
        }
        return conference.onCommand(cmd);
      }),
    );
  }

  //#region Tokbox specific methods

  reconnect() {
    return new Observable(subscriber => {
      if (!(this.conference instanceof TokboxConferenceProvider)) {
        console.warn('Calling reconnect in non Tokbox connection');
        subscriber.complete();
        return;
      }

      this.conference.dispose();

      const currentConfig = this.conference.config;
      this.setConference(
        new TokboxConferenceProvider(currentConfig, this.logger),
      );

      const sub = this.init()
        .pipe(
          mergeMap(() => {
            return this.waitForConnection();
          }),
          timeout(5_000),
        )
        .subscribe(subscriber);

      subscriber.add(() => {
        sub.unsubscribe();
      });
    });
  }

  onRemoteStreamCreated() {
    return this.tokboxConference$.pipe(
      switchMap(conference => {
        if (!conference) {
          return EMPTY;
        }

        return conference.onRemoteStreamCreated();
      }),
    );
  }

  connect() {
    return this.tokboxConference.connect();
  }

  forceDisconnect(id: string) {
    return this.tokboxConference.forceDisconnect(id);
  }

  subscribe(id: string) {
    if (!(this.conference instanceof TokboxConferenceProvider)) {
      console.warn('Calling subscribe in non Tokbox connection');
      return EMPTY;
    }
    return this.waitForRemoteStream(id).pipe(
      mergeMap(() => {
        return this.tokboxConference.subscribe(id);
      }),
    );
  }

  unsubscribe(id: string) {
    return this.tokboxConference.unsubscribe(id);
  }

  //#endregion

  private waitForRemoteStream(id: string) {
    return this.tokboxConference
      .hasRemoteStream(id)
      .pipe(filter(Boolean), take(1));
  }

  waitForConnection() {
    return this.getStatus().pipe(
      filter(status => status === ConferenceStatus.connected),
      take(1),
    );
  }

  private setConference(nextConference: ConferenceProvider) {
    this.conferenceSubject.next(nextConference);
  }
}
