import { Actions, ofType } from '@ngrx/effects';
import { pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as WaitingRoomActions from './waiting-room.actions';

export const takeUntilKnockFinished = <T>(actions$: Actions) => {
  return pipe(
    takeUntil<T>(
      actions$.pipe(
        ofType(
          WaitingRoomActions.guestAdmitted,
          WaitingRoomActions.guestAdmissionDenied,
          WaitingRoomActions.cancelKnockSuccess,
        ),
      ),
    ),
  );
};
