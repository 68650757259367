import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PLGuidService {
  generateUUID = function () {
    let d = Date.now();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
  };
}
