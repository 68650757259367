import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WindowNetworkStatusEffects } from './store/effects';
import { WindowVisibilityStateEffects } from './store/effects/visibility-state.effects';
import { windowFeatureKey } from './store/window.model';
import { windowReducer } from './store/window.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(windowFeatureKey, windowReducer),
    EffectsModule.forFeature([
      WindowNetworkStatusEffects,
      WindowVisibilityStateEffects,
    ]),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class WindowModule {}
