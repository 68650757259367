import { Injectable } from '@angular/core';
import { environment } from '@root/src/environments/environment';
import { LazyScriptLoaderService } from './lazy-script-loader.service';
declare var zE: any;

@Injectable({ providedIn: 'root' })
export class ZenDeskService {
  private _chatAvailable = false;
  get chatAvailable() {
    return this._chatAvailable;
  }

  constructor(private lazyLoader: LazyScriptLoaderService) {
    this.init();
  }

  init() {
    const zendeskKey = environment.zendesk_key;
    if (!zendeskKey) {
      this._chatAvailable = false;
    }
    this.lazyLoader
      .loadScript(
        `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
        'ze-snippet',
      )
      .then(() => {
        zE('messenger', 'hide');
        zE('messenger:on', 'close', function () {
          zE('messenger', 'hide');
        });
        // Avoid opening the chat when the welcome message is received
        let welcomeMessageSeen = false;
        zE('messenger:on', 'unreadMessages', count => {
          if (count > 0) {
            if (welcomeMessageSeen) {
              this.startChat();
            }
            welcomeMessageSeen = true;
          }
        });
        this._chatAvailable = true;
      });
  }

  startChat() {
    zE('messenger', 'show');
    zE('messenger', 'open');
  }
}
