import { createSelector } from '@ngrx/store';
import { AppState } from '@app/store';

export const selectLicensingState = (state: AppState) => state.licensing;

export const selectIsFreemium = createSelector(selectLicensingState, state => {
  return state.isFreemiumUser;
});

export const selectRemainingMinutes = createSelector(
  selectLicensingState,
  state => {
    return state.remainingMinutes;
  },
);

export const selectIsSessionInProgress = createSelector(
  selectLicensingState,
  state => {
    return state.isSessionInProgress;
  },
);

export const selectCurrentSession = createSelector(
  selectLicensingState,
  state => {
    return state.currentSessionData;
  },
);

export const selectIsFreemiumOver = createSelector(
  selectLicensingState,
  state => {
    return state.isFreemiumUser && state.remainingMinutes <= 0;
  },
);

export const selectIsTEProvider = createSelector(
  selectLicensingState,
  state => {
    return state.isTEProvider;
  },
);
