import {
  PLGameConfig,
  PLGameDb,
  PLVendorGame,
  PLGameAdapterUrlParams,
} from './pl-vendor-game.service';

// =======================================================================
// Vendor Game Definitions
//
// Vendor games require two basic operations that depend on knowledge of
// game-specific criteria:
//   * start game with config options
//   * load iframe src with parameters
//
// Vendor game definitions implement an Adapter interface that allows
// the room to access custom game configuration in a generic way.
//
// Adding a new game requires the following development:
//   * add a game activity in django (and workspace migration)
//   * add a PLVendorGame for the new game
//   * implement PLVendorGame interface - events, getAdapter(), newFormData()
//   * update PLVendorGames and PLVendorGamesByActivity
//   * make a corresponding rail (drawer) component to control the game
//   * add events to signal between the rail and the game components
// =======================================================================

// Gametable (Spencer) / Checkers
const checkers: PLVendorGame = {
  vendor: 'gametable',
  name: 'checkers',
  setConfig: (plGameDb: PLGameDb, config: PLGameConfig) => {
    const rules = plGameDb.ref('rules');
    rules.set(
      config,
      () => console.log(`${checkers.name} game set config OK`, config),
      reason => console.log(`${checkers.name} game set config ERR`, reason),
    );
  },
  getIframeUrl: (params: PLGameAdapterUrlParams) => {
    const { isRoomOwner, clientId } = params;
    return `/assets/static-files/vendor-games/gametable/versions/v1/checkers/game.html?isRoomOwner=${isRoomOwner}&clientId=${clientId}`;
  },
  newFormData: () => {
    return {
      boardSizeOpts: [
        { label: '8x8', value: 8 },
        { label: '10x10', value: 10 },
        { label: '12x12', value: 12 },
      ],
      boardThemeOpts: [
        {
          label: 'Default',
          value: 'Default',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/checkers/theme-default.svg',
        },
        {
          label: 'Classic',
          value: 'Classic',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/checkers/theme-classic.svg',
        },
        {
          label: 'Wood',
          value: 'Wood',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/checkers/theme-wood.svg',
        },
        {
          label: 'Cloth',
          value: 'Cloth',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/checkers/theme-cloth.svg',
        },
      ],
      playerOpts: [],
      model: {
        boardSize: 8,
        boardTheme: 'Default',
        showMoves: true,
        bottomMovesFirst: true,
        playerTop: null,
        playerBottom: null,
      },
    };
  },
};

// Gametable (Spencer) / Dots and Boxes
const dotsAndBoxes: PLVendorGame = {
  vendor: 'gametable',
  name: 'dotsAndBoxes',
  setConfig: (plGameDb: PLGameDb, config: PLGameConfig) => {
    const rules = plGameDb.ref('rules');
    rules.set(
      config,
      () => console.log(`${checkers.name} game set config OK`, config),
      reason => console.log(`${checkers.name} game set config ERR`, reason),
    );
  },
  getIframeUrl: (params: PLGameAdapterUrlParams) => {
    const { isRoomOwner, clientId } = params;
    return `/assets/static-files/vendor-games/gametable/versions/v1/dotsAndBoxes/game.html?isRoomOwner=${isRoomOwner}&clientId=${clientId}`;
  },
  newFormData: () => {
    return {
      boardSizeOpts: [
        { label: '3x2', value: '3x2' },
        { label: '5x4', value: '5x4' },
        { label: '8x6', value: '8x6' },
        { label: '11x9', value: '11x9' },
      ],
      boardThemeOpts: [
        {
          label: 'Default',
          value: 'Default',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/dotsAndBoxes/theme-default.svg',
        },
        {
          label: 'Classic',
          value: 'Classic',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/dotsAndBoxes/theme-classic.svg',
        },
        {
          label: 'Dark',
          value: 'Dark',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/dotsAndBoxes/theme-dark.svg',
        },
        {
          label: 'Sunset',
          value: 'Sunset',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/dotsAndBoxes/theme-sunset.svg',
        },
      ],
      numPlayersOpts: [
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
      ],
      playerOpts: [],
      model: {
        boardSize: '3x2',
        boardTheme: 'Default',
        numPlayers: 2,
        player1: null,
        player2: null,
        player3: null,
        player4: null,
      },
    };
  },
};

// Gametable (Spencer) / Blokd
const blokd: PLVendorGame = {
  vendor: 'gametable',
  name: 'blokd',
  setConfig: (plGameDb: PLGameDb, config: PLGameConfig) => {
    const rules = plGameDb.ref('rules');
    rules.set(
      config,
      () => console.log(`${blokd.name} game set config OK`, config),
      reason => console.log(`${blokd.name} game set config ERR`, reason),
    );
  },
  getIframeUrl: (params: PLGameAdapterUrlParams) => {
    const { isRoomOwner, clientId } = params;
    return `/assets/static-files/vendor-games/gametable/versions/v1/blokd/game.html?isRoomOwner=${isRoomOwner}&clientId=${clientId}`;
  },
  newFormData: () => {
    return {
      boardThemeOpts: [
        {
          label: 'Default',
          value: 'Default',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/blokd/theme-default.svg',
        },
        {
          label: 'Classic',
          value: 'Classic',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/blokd/theme-classic.svg',
        },
        {
          label: 'Dark',
          value: 'Vibrant',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/blokd/theme-dark.svg',
        },
        {
          label: 'Animals',
          value: 'Animal',
          thumbnailUrl:
            '/assets/static-files/vendor-games/gametable/versions/v1/blokd/theme-animals.svg',
        },
      ],
      numPlayersOpts: [
        { label: '2', value: 2 },
        { label: '4', value: 4 },
      ],
      playerOpts: [],
      model: {
        boardTheme: 'Default',
        numPlayers: 2,
        player1: null,
        player2: null,
        player3: null,
        player4: null,
      },
    };
  },
};

// Gametable (Spencer) / Trio
const trio: PLVendorGame = {
  vendor: 'gametable',
  name: 'trio',
  setConfig: (plGameDb: PLGameDb, config: PLGameConfig) => {
    const rules = plGameDb.ref('rules');
    rules.set(
      config,
      () => console.log(`${trio.name} game set config OK`, config),
      reason => console.log(`${trio.name} game set config ERR`, reason),
    );
  },
  getIframeUrl: (params: PLGameAdapterUrlParams) => {
    const { isRoomOwner, clientId } = params;
    return `/assets/static-files/vendor-games/gametable/versions/v1/trio/game.html?isRoomOwner=${isRoomOwner}&clientId=${clientId}`;
  },
  newFormData: () => {
    return {
      boardSizeOpts: [],
      numPlayersOpts: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
      ],
      playerOpts: [],
      model: {
        boardSize: 8,
        boardTheme: 'Default',
        showMoves: true,
        bottomMovesFirst: true,
        playerTop: null,
        playerBottom: null,
        players: [
          {
            position: 1,
            value: '',
          },
          {
            position: 2,
            value: '',
          },
          {
            position: 3,
            value: '',
          },
          {
            position: 4,
            value: '',
          },
        ],
      },
    };
  },
};

// Gametable (Spencer) / Teamword
const teamword: PLVendorGame = {
  vendor: 'gametable',
  name: 'teamword',
  setConfig: (plGameDb: PLGameDb, config: PLGameConfig) => {
    const rules = plGameDb.ref('rules');
    rules.set(
      config,
      () => console.log(`${teamword.name} game set config OK`, config),
      reason => console.log(`${teamword.name} game set config ERR`, reason),
    );
  },
  getIframeUrl: (params: PLGameAdapterUrlParams) => {
    const { isRoomOwner, clientId } = params;
    return `/assets/static-files/vendor-games/gametable/versions/v1/teamword/game.html?isRoomOwner=${isRoomOwner}&clientId=${clientId}`;
  },
  newFormData: () => {
    return {
      numPlayersOpts: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
      ],
      playerOpts: [],
      numGuessesOpts: [
        { label: '6', value: 6 },
        { label: '8', value: 8 },
        { label: '10', value: 10 },
      ],
      wordLengthOpts: [
        { label: 'Any', value: -1 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
      ],
      levelOpts: [
        { label: 'Kindergarten', value: 'kindergarten' },
        { label: 'Lower elementary', value: 'lower-elementary' },
        { label: 'Upper elementary', value: 'upper-elementary' },
        { label: 'Middle school', value: 'middle-school' },
        { label: 'High school', value: 'high-school' },
      ],
      model: {
        boardTheme: 'Default',
        numPlayers: 1,
        numGuesses: 6,
        wordType: 'random',
        level: 'kindergarten',
        wordLength: -1,
        manualWord: '',
        player1: null,
        player2: null,
        player3: null,
        player4: null,
      },
    };
  },
};

// =======================================================================
// List of vendor games available to the room
// =======================================================================
export const PLVendorGames = {
  checkers,
  dotsAndBoxes,
  blokd,
  trio,
  teamword,
};

// =======================================================================
// List of game activity django item keys
// =======================================================================
export const PLVendorGamesByActivity = {
  'game-gametable-checkers': PLVendorGames.checkers,
  'game-gametable-dots-and-boxes': PLVendorGames.dotsAndBoxes,
  'game-gametable-blokd': PLVendorGames.blokd,
  'game-gametable-trio': PLVendorGames.trio,
  'game-gametable-teamword': PLVendorGames.teamword,
};
