import { createAction, props } from '@ngrx/store';
import { DealCountOptions } from '../pl-go-fish-types.types';
import { CardDisplayOption } from '@root/src/app/common/components/card-display-options/card-display-options.component';

export const GO_FISH_UPDATE_CARD_DISPLAY = 'GO_FISH_UPDATE_CARD_DISPLAY';
export const GO_FISH_UPDATE_DEAL_COUNT = 'GO_FISH_UPDATE_DEAL_COUNT';
export const GO_FISH_XRAY_STATUS = 'GO_FISH_XRAY_STATUS';
export const GO_FISH_TOGGLE_GAME_ACTIVE = 'GO_FISH_TOGGLE_GAME_ACTIVE';
export const GO_FISH_UPDATE_CARDS = 'GO_FISH_UPDATE_CARDS';
export const GO_FISH_UPDATE_DECK_ID = 'GO_FISH_UPDATE_DECK_ID';

const goFishToggleGameActive = createAction(
  GO_FISH_TOGGLE_GAME_ACTIVE,
  props<{ isGameActive: boolean }>(),
);
const goFishUpdateCardDisplay = createAction(
  GO_FISH_UPDATE_CARD_DISPLAY,
  props<{ cardDisplay: CardDisplayOption }>(),
);
const goFishUpdateDealCount = createAction(
  GO_FISH_UPDATE_DEAL_COUNT,
  props<{ dealCount: DealCountOptions }>(),
);
const goFishXrayStatus = createAction(GO_FISH_XRAY_STATUS, props<{ any }>());

const goFishUpdateCards = createAction(
  GO_FISH_UPDATE_CARDS,
  props<{ cards: any[] }>(),
);
const goFishUpdateDeckId = createAction(
  GO_FISH_UPDATE_DECK_ID,
  props<{ deckId: number }>(),
);
export const GoFishActions = {
  goFishUpdateCardDisplay,
  goFishUpdateDealCount,
  goFishXrayStatus,
  goFishToggleGameActive,
  goFishUpdateCards,
  goFishUpdateDeckId,
};
