import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QueueItem } from '@library/common/services/queues.service';

export type EditQueueDetailsData = {
  queue: QueueItem;
  showDescription?: boolean;
  title?: string;
  saveButtonLabel?: string;
};

@Component({
  selector: 'pl-edit-queue-details',
  templateUrl: './edit-queue-details.component.html',
  styleUrls: ['./edit-queue-details.component.scss'],
})
export class EditQueueDetailsComponent implements OnInit {
  protected queueForm: FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
  }>;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditQueueDetailsData,
    private dialogRef: MatDialogRef<
      EditQueueDetailsComponent,
      Partial<QueueItem>
    >,
  ) {}

  ngOnInit() {
    this.queueForm = this.formBuilder.group({
      name: this.formBuilder.control(this.data.queue.name, [
        Validators.required,
      ]),
      description: this.formBuilder.control(this.data.queue.description),
    });
  }

  protected save() {
    if (this.queueForm.valid) {
      this.dialogRef.close(this.queueForm.value);
    }
  }
}
