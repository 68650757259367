<div class="cards-options">
  <label>{{ label }}</label>
  <div class="card-display-options" [class.preview]="isPreview">
    <div
      *ngIf="useOptions.includes(CardDisplayOption.ImageAndText)"
      class="card-display-options-item"
      (click)="chooseOption(CardDisplayOption.ImageAndText)">
      <div class="card-display-options-item-subcontainer">
        <mat-icon
          *ngIf="
            selectedOption !== CardDisplayOption.ImageAndText;
            else displayCheck1
          "
          svgIcon="display-img-and-text"></mat-icon>
        <ng-template #displayCheck1>
          <mat-icon *ngIf="!isPreview" svgIcon="display-check"></mat-icon>
          <mat-icon
            *ngIf="isPreview"
            svgIcon="display-img-and-text-selected"></mat-icon>
        </ng-template>
        <div>Text & image</div>
      </div>
    </div>
    <div
      *ngIf="useOptions.includes(CardDisplayOption.TextOnly)"
      class="card-display-options-item"
      (click)="chooseOption(CardDisplayOption.TextOnly)">
      <div class="card-display-options-item-subcontainer">
        <mat-icon
          *ngIf="
            selectedOption !== CardDisplayOption.TextOnly;
            else displayCheck2
          "
          svgIcon="display-text-only"></mat-icon>
        <ng-template #displayCheck2>
          <mat-icon *ngIf="!isPreview" svgIcon="display-check"></mat-icon>
          <mat-icon
            *ngIf="isPreview"
            svgIcon="display-text-only-selected"></mat-icon>
        </ng-template>
        <div>Text only</div>
      </div>
    </div>
    <div
      *ngIf="useOptions.includes(CardDisplayOption.ImageOnly)"
      class="card-display-options-item"
      (click)="chooseOption(CardDisplayOption.ImageOnly)">
      <div class="card-display-options-item-subcontainer">
        <mat-icon
          *ngIf="
            selectedOption !== CardDisplayOption.ImageOnly;
            else displayCheck3
          "
          svgIcon="display-img-only"></mat-icon>
        <ng-template #displayCheck3>
          <mat-icon *ngIf="!isPreview" svgIcon="display-check"></mat-icon>
          <mat-icon
            *ngIf="isPreview"
            svgIcon="display-img-only-selected"></mat-icon>
        </ng-template>
        <div>Image only</div>
      </div>
    </div>
  </div>
</div>
