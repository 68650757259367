import { createAction, props } from '@ngrx/store';
import { Room } from './room.model';

const setLoading = createAction('/room/setLoading');

const setData = createAction('/room/setData', props<{ data: Room }>());

const setSuccess = createAction('/room/setSuccess');

const setError = createAction('/room/setError', props<{ error: any }>());

export const RoomActions = {
  setLoading,
  setData,
  setSuccess,
  setError,
};
