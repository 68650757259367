import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import moment from 'moment';

import { PLHttpService } from '../pl-http';
import { catchError, map, switchMap } from 'rxjs/operators';

export interface AISummaryReportParams {
  text: string;
}

@Injectable({ providedIn: 'root' })
export class PLAISummaryService {
  constructor(private plHttp: PLHttpService) {}

  generateSummary(notesReportParams: AISummaryReportParams): Observable<any> {
    return this.plHttp.save('summarize', notesReportParams);
  }

  generateSummaryForClient(clientUuid, startDate, endDate): Observable<string> {
    return this.plHttp
      .get('records', {
        client: clientUuid,
        ordering: 'appointment__start',
        expand: 'appointment',
      })
      .pipe(
        switchMap((res: any) => {
          let text = '';
          for (const record of res.results) {
            const recordDate = moment(record.appointment_expanded.start);
            if (recordDate.isBefore(startDate) || recordDate.isAfter(endDate)) {
              continue;
            }
            text += `Session on ${recordDate.format('MMMM D, YYYY')}:\n`;
            let recordNotes = JSON.parse(record.notes);
            if (recordNotes.subjective)
              text += 'Subjective: ' + recordNotes.subjective + '\n';
            if (recordNotes.objective)
              text += 'Objective:' + recordNotes.objective + '\n';
            if (recordNotes.assessment)
              text += 'Assessment:' + recordNotes.assessment + '\n';
            if (recordNotes.plan) text += 'Plan:' + recordNotes.plan + '\n';
            if (recordNotes.notes)
              text += 'General Notes:' + recordNotes.notes + '\n';
          }
          return this.generateSummary({ text });
        }),
        map((summaryResponse: any) => summaryResponse.summary),
        catchError(error => {
          console.error('Error generating summary:', error);
          return of('Error generating summary.');
        }),
      );
  }
}
