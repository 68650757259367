import moment from 'moment';
import { Injectable } from '@angular/core';

import { environment } from '@root/src/environments/environment';
import { PLEmploymentStatus, User, Provider } from '@modules/user/user.model';
import { BrowserIdService } from '@common/services/browser-id.service';

import { PLTrackingService } from './tracking.service';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';

type PayrollType = 'W2' | '1099' | null;
interface ProviderType {
  code: string;
  long_name: string;
  short_name: string;
  uuid: string;
}
interface PendoVisitor extends pendo.IdentityMetadata {
  id: string; // Required if user is logged in, default creates anonymous ID
  email: string; // Recommended if using Pendo Feedback, or NPS Email
  full_name: string; // Recommended if using Pendo Feedback
  role?: string;

  // Below is a list of custom fields, that aren't specific to Pendo:
  // machine_id: string, // TODO: not available for now
  username: string;
  auth_groups: string[];
  years_with_presence: number;
  isHijacked: boolean;
  browser_id: string;
  screen_resolution: string;
  discipline?: string[];
  billing_state?: string;
  payroll_type?: PayrollType;
  employment_status?: string;
  complete_onboarding?: boolean;
  sub_status?: string;
}

interface PendoAccount extends pendo.IdentityMetadata {
  id: string; // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
  name?: string;
  is_paying?: boolean; // Recommended if using Pendo Feedback
  monthly_value?: boolean; // Recommended if using Pendo Feedback
  planLevel?: string;
  planPrice?: string;
  creationDate?: string;

  // You can add any additional account level key-values here,
  // as long as it's not one of the above reserved names.
}

const pendoScript = `window.loadPendo = function(apiKey){
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
  v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
};`;

@Injectable()
export class PendoTrackingService implements PLTrackingService {
  pendoKey: string;
  isInitialized = false;

  constructor(
    private browserIdService: BrowserIdService,
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
  ) {
    this.pendoKey = environment.pendo_key;

    const lsPendoKey = localStorage.getItem('pl-room-pendo-key');

    // We allow overriding default pendo key via Local Storage for testing purposes
    // But only on non-production environments
    if (!environment.production && lsPendoKey) {
      this.pendoKey = lsPendoKey === 'undefined' ? null : lsPendoKey;
    }

    this.loadPendo();
  }

  async setUser(user: User, isHijacked = false) {
    if (!this.isInitialized) return;

    const visitor = await this.createVisitor(user, isHijacked);

    const account: PendoAccount = {
      id: this.pendoKey,
    };

    window.pendo.initialize({
      visitor,
      account,
    });
  }

  private loadPendo() {
    if (!this.pendoKey) return;

    const loadPendoScriptElement = document.createElement('script');
    loadPendoScriptElement.type = 'text/javascript';
    loadPendoScriptElement.innerHTML = pendoScript;
    document.head.appendChild(loadPendoScriptElement);

    window.loadPendo(this.pendoKey);
    this.isInitialized = true;
  }

  private getProviderTypes() {
    return new Promise<ProviderType[]>((resolve, reject) => {
      this.plHttp.get('', {}, this.plUrls.urls.providerTypes).subscribe({
        next: data => {
          resolve(data.results);
        },
        error: e => {
          reject(e);
        },
      });
    });
  }

  private async createVisitor(
    user: User,
    isHijacked: boolean,
  ): Promise<PendoVisitor> {
    const screenWidth = window.screen.width * window.devicePixelRatio;
    const screenHeight = window.screen.height * window.devicePixelRatio;
    const screenResolution = `${screenWidth} x ${screenHeight}`;

    const visitor: PendoVisitor = {
      isHijacked,
      id: user.uuid,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
      username: user.username,
      auth_groups: user.groups,
      years_with_presence: moment().diff(user.created, 'years', false),
      browser_id: this.browserIdService.browserId,
      screen_resolution: screenResolution,
    };

    if (user?.xProvider) {
      try {
        const providerTypes = await this.getProviderTypes();
        visitor.discipline = providerTypes
          .filter(pt => user.xProvider.provider_types.includes(pt.uuid))
          .map(pt => pt.long_name);
      } catch (e) {
        console.error(`Error getting provider types: ${e}`);
        visitor.discipline = user.xProvider.provider_types;
      }
      visitor.billing_state = user.xProvider.billing_state;
      visitor.payroll_type = this.getPayrollStatus(user.xProvider);
      visitor.employment_status = user.xProvider.employment_status;
      visitor.complete_onboarding =
        user.xProvider.is_onboarding_wizard_complete;
      visitor.sub_status = user.xProvider.provider_sub_status;
    }

    return visitor;
  }

  private getPayrollStatus(provider: Provider): PayrollType {
    let employmentStatus: PLEmploymentStatus = provider.employment_status;
    switch (employmentStatus) {
      case PLEmploymentStatus.FULL_TIME_EMPLOYEE:
      case PLEmploymentStatus.PART_TIME_EMPLOYEE:
      case PLEmploymentStatus.SALARIED_EMPLOYEE:
        return 'W2';
      case PLEmploymentStatus.INDEPENDENT_CONTRACTOR:
      case PLEmploymentStatus.SUBCONTRACTOR:
        return '1099';
      default:
        return null;
    }
  }
}
