import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

export type ViewMode = 'light' | 'dark';

@Component({
  selector: 'pl-snackbar',
  templateUrl: './pl-snackbar.component.html',
  styleUrls: ['./pl-snackbar.component.scss'],
})
export class PLSnackbarComponent implements AfterViewInit, OnInit {
  @ViewChild('snackbarContent') content: ElementRef;

  /**
   * Which color theme variant of the snackbar to use.
   */
  @Input() mode: ViewMode = 'dark';
  /**
   * Is the snackbar able to be closed.
   */
  @Input() dismissible: boolean = true;
  /**
   * Should the action button be disabled.
   */
  @Input() disableAction: boolean = false;
  /**
   * Text label for the action button.
   */
  @Input() actionLabel: string = '';

  /**
   * The action button has been clicked.
   */
  @Output() actionClicked = new EventEmitter<void>();
  /**
   * The close button has been clicked.
   */
  @Output() dismissed = new EventEmitter<void>();

  useColumn = false;
  multiline = false;

  ngOnInit() {
    if (this.actionLabel?.length > 5) {
      this.useColumn = true;
    }
    if (!this.actionLabel || this.actionLabel === '') {
      this.disableAction = true;
    }
  }

  ngAfterViewInit() {
    if (this.content.nativeElement.childElementCount > 1) {
      this.multiline = true;
    }
  }

  handleActionClicked() {
    this.actionClicked.emit();
  }

  handleDismiss() {
    this.dismissed.emit();
  }
}
