import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { PLCommonComponentsModule } from '@common/components';

import { PLInactiveComponent } from './pl-inactive/pl-inactive.component';
import { PLInactiveService } from './pl-inactive/pl-inactive.service';
import { PLRoomCleanupService } from './pl-inactive/pl-room-cleanup.service';

import { reducer } from './store';

@NgModule({
  imports: [
    CommonModule,
    PLCommonComponentsModule,
    StoreModule.forFeature('auth', reducer),
  ],
  exports: [PLInactiveComponent],
  declarations: [PLInactiveComponent],
  providers: [PLInactiveService, PLRoomCleanupService],
})
export class PLUserModule {}

export { PLInactiveComponent } from './pl-inactive/pl-inactive.component';
export { PLInactiveService } from './pl-inactive/pl-inactive.service';
