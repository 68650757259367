import { Injectable } from '@angular/core';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FreemiumStatusService {
  private consumedMinutes = 0;

  constructor(private plHttp: PLHttpService, private plUrls: PLUrlsService) {}

  getUserAccount(): Observable<any> {
    const url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/accounts/`;
    return this.plHttp.get('', {}, url);
  }

  getCurrentStatus(accountId, userId): Observable<any> {
    const url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/accounts/${accountId}/users/${userId}/`;
    return this.plHttp.get('', {}, url);
  }

  updateConsumedMinutes(): Observable<any> {
    this.consumedMinutes++;
    const url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/users/discount_minutes/`;
    const data = {
      minutes: this.consumedMinutes,
    };
    return this.plHttp.save('', data, url).pipe(
      map(result => {
        this.consumedMinutes = 0;
        return result;
      }),
    );
  }
}
