import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store';
import { selectIsHijackedUser } from './modules/user/store';

@Component({
  selector: 'pl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  isHijacked$: Observable<boolean>;

  constructor(store: Store<AppState>) {
    this.isHijacked$ = store.select(selectIsHijackedUser);
  }

  onActivate() {
    const loader = document.querySelector('#page-loader');
    if (loader) {
      loader.remove();
    }
  }
}
