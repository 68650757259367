import {
  createAction,
  createReducer,
  createSelector,
  on,
  props,
} from '@ngrx/store';
import { StickerTemplate } from '../modules/room/pl-activity/game/pl-scenes/pl-scenes-factory.service';
import { AppState } from '../store/store.model';

export type GameSceneId = 'scenes' | 'potatoHead';

export type GameSceneBuilder = Record<
  GameSceneId,
  {
    sceneName: string;
    stickers: StickerTemplate[];
    stickerPackName: string;
    preview: boolean;
    currentTabIndex: number;
  }
>;

const initialState: GameSceneBuilder = {
  scenes: {
    sceneName: null,
    stickers: [],
    stickerPackName: 'all',
    preview: false,
    currentTabIndex: 0,
  },
  potatoHead: {
    sceneName: null,
    stickers: [],
    stickerPackName: 'all',
    preview: false,
    currentTabIndex: 0,
  },
};

interface BaseActionPayload {
  gameId: GameSceneId;
}

interface AddStickerActionPayload extends BaseActionPayload {
  stickers: StickerTemplate[];
}

interface SetSceneNameActionPayload extends BaseActionPayload {
  sceneName: string;
}

interface SetStickerPackNameActionPayload extends BaseActionPayload {
  stickerPackName: string;
}

interface TogglePreviewActionPayload extends BaseActionPayload {
  preview: boolean;
}

interface ToggleStickerDisplayActionPayload extends BaseActionPayload {
  showStickers: boolean;
}

interface SetCurrentTabIndexActionPayload extends BaseActionPayload {
  tabIndex: number;
}

export const actions = {
  clearStickers: createAction(
    'SCENES_CLEAR_STICKERS',
    props<BaseActionPayload>(),
  ),
  clearTrayStickers: createAction(
    'SCENES_CLEAR_TRAY_STICKERS',
    props<BaseActionPayload>(),
  ),
  addStickers: createAction(
    'SCENES_ADD_STICKERS',
    props<AddStickerActionPayload>(),
  ),
  setSceneName: createAction(
    'SCENES_SET_SCENE_NAME',
    props<SetSceneNameActionPayload>(),
  ),
  setStickerPackName: createAction(
    'SCENES_SET_STICKER_PACK_NAME',
    props<SetStickerPackNameActionPayload>(),
  ),
  togglePreview: createAction(
    'SCENES_PREVIEW_TOGGLE',
    props<TogglePreviewActionPayload>(),
  ),
  toggleStickerDisplay: createAction(
    'SCENES_STICKER_DISPLAY_TOGGLE',
    props<ToggleStickerDisplayActionPayload>(),
  ),
  setCurrentTabIndex: createAction(
    'SCENES_SET_CURRENT_TAB_INDEX',
    props<SetCurrentTabIndexActionPayload>(),
  ),
};

export const reducer = createReducer(
  initialState,
  on(
    actions.addStickers,
    (state: GameSceneBuilder, action: AddStickerActionPayload) => {
      const gameState = state[action.gameId];
      return {
        ...state,
        [action.gameId]: {
          ...gameState,
          stickers: [...(gameState.stickers || []), ...action.stickers],
        },
      };
    },
  ),
  on(
    actions.clearStickers,
    (state: GameSceneBuilder, action: BaseActionPayload) => ({
      ...state,
      [action.gameId]: {
        ...state[action.gameId],
        stickers: [],
      },
    }),
  ),
  on(
    actions.setSceneName,
    (state: GameSceneBuilder, action: SetSceneNameActionPayload) => ({
      ...state,
      [action.gameId]: {
        ...state[action.gameId],
        sceneName: action.sceneName,
      },
    }),
  ),
  on(
    actions.setStickerPackName,
    (state: GameSceneBuilder, action: SetStickerPackNameActionPayload) => ({
      ...state,
      [action.gameId]: {
        ...state[action.gameId],
        stickerPackName: action.stickerPackName,
      },
    }),
  ),
  on(
    actions.togglePreview,
    (state: GameSceneBuilder, action: TogglePreviewActionPayload) => ({
      ...state,
      [action.gameId]: {
        ...state[action.gameId],
        preview: action.preview,
      },
    }),
  ),
  on(
    actions.setCurrentTabIndex,
    (state: GameSceneBuilder, action: SetCurrentTabIndexActionPayload) => ({
      ...state,
      [action.gameId]: {
        ...state[action.gameId],
        currentTabIndex: action.tabIndex,
      },
    }),
  ),
);

export const gameScenesSelector = (gameId: GameSceneId) => (state: AppState) =>
  state.gameSceneBuilder[gameId];

export const stickerPackNameSelector = (gameId: GameSceneId) =>
  createSelector(gameScenesSelector(gameId), state => state.stickerPackName);

export const sceneNameSelector = (gameId: GameSceneId) =>
  createSelector(gameScenesSelector(gameId), state => state.sceneName);

export const currentTabIndexSelector = (gameId: GameSceneId) =>
  createSelector(gameScenesSelector(gameId), state => state.currentTabIndex);
