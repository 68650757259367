import { Observable } from 'rxjs';

export function fromNodeCallback<
  A extends readonly unknown[],
  R extends readonly unknown[],
>(
  callbackFunc: (...args: [...A, (err: any, ...res: R) => void]) => void,
): (...arg: A) => Observable<R extends [] ? void : R extends [any] ? R[0] : R>;
export function fromNodeCallback(
  callbackFunc: (...args: [...any[], (err: any, ...res: any) => void]) => void,
) {
  return function(...args: any[]) {
    return new Observable<any>((subscriber) => {
      const handler = (...results: any[]) => {
        const err = results.shift();
        if (err != null) {
          subscriber.error(err);
          subscriber.complete();
          return;
        }
        subscriber.next(1 < results.length ? results : results[0]);
        subscriber.complete();
      };
      callbackFunc(...args, handler);
    });
  };
}
