import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { LeavingPageService } from '@common/window/services';
import { WaitingRoomConferenceService } from '../../waiting-room-conference.service';

import * as WaitingRoomActions from '../waiting-room.actions';
import { KnockData } from '../waiting-room.model';
import { takeUntilKnockFinished } from '../waiting-room.operators';

@Injectable()
export class WaitingRoomGuestKnockEffects {
  knock$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.knock),
      mergeMap(({ knockData }) => {
        return this.knock(knockData).pipe(
          map(() => WaitingRoomActions.knockSuccess({ knockData })),
          catchError(err => {
            console.error('WaitingRoom knock error', { ...knockData, err });
            return of(WaitingRoomActions.knockError({ error: err }));
          }),
        );
      }),
    );
  });

  cancelKnock$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.cancelKnock),
      mergeMap(({ id }) => {
        return this.waitingRoomConference.cancelKnock(id).pipe(
          map(() => WaitingRoomActions.cancelKnockSuccess({ id })),
          catchError(err => {
            console.error('WaitingRoom deny cancel knock error', { id, err });
            return of(WaitingRoomActions.cancelKnockError({ error: err }));
          }),
        );
      }),
    );
  });

  reknockOnHostConnected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.knockSuccess),
      switchMap(knockData => {
        return this.waitingRoomConference.onHostConnected().pipe(
          takeUntilKnockFinished(this.actions$),
          map(() => WaitingRoomActions.knock({ ...knockData })),
        );
      }),
    );
  });

  leavePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.knockSuccess),
      switchMap(({ knockData }) => {
        const { id } = knockData;
        return this.leavingPageService.onLeavingPage().pipe(
          takeUntilKnockFinished(this.actions$),
          map(() => WaitingRoomActions.cancelKnock({ id })),
        );
      }),
    );
  });

  private knock(knockData: KnockData) {
    return this.waitingRoomConference
      .waitForConnection()
      .pipe(mergeMap(() => this.waitingRoomConference.knock(knockData)));
  }

  constructor(
    private actions$: Actions,
    private waitingRoomConference: WaitingRoomConferenceService,
    private leavingPageService: LeavingPageService,
  ) {}
}
