import { createReducer, on } from '@ngrx/store';
import { NetworkStatusService, VisibilityService } from '../services';
import * as WindowActions from './window.actions';
import { WindowState } from './window.model';

export const windowInitialState: WindowState = {
  networkStatus: NetworkStatusService.currentValue,
  visibilityState: VisibilityService.currentValue,
};

export const windowReducer = createReducer(
  windowInitialState,
  on(WindowActions.networkStatusChanged, (state, { networkStatus }) => {
    return {
      ...state,
      networkStatus,
    };
  }),
  on(WindowActions.visibilityChanged, (state, { visibilityState }) => {
    return {
      ...state,
      visibilityState,
    };
  }),
);
