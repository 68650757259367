import { createAction, props } from '@ngrx/store';

export const ACTIVATE_TEAM_WRITE = 'ACTIVATE_TEAM_WRITE';
export const RESET_TEAM_WRITE_DOC = 'RESET_TEAM_WRITE_DOC';
export const SAVE_TEAM_WRITE_DOC = 'SAVE_TEAM_WRITE_DOC';
export const NEW_TEAM_WRITE_DOC = 'NEW_TEAM_WRITE_DOC';
export const LOAD_TEAM_WRITE_DOC = 'LOAD_TEAM_WRITE_DOC';
export const DELETE_TEAM_WRITE_DOC = 'DELETE_TEAM_WRITE_DOC';
export const TRASH_TEAM_WRITE_DOC = 'TRASH_TEAM_WRITE_DOC';
export const RESTORE_TEAM_WRITE_DOC = 'RESTORE_TEAM_WRITE_DOC';
export const TEAM_WRITE_DOC_LOADED = 'TEAM_WRITE_DOC_LOADED';
export const TEAM_WRITE_READY = 'TEAM_WRITE_READY';
export const TEAM_WRITE_DOCUMENTS = 'TEAM_WRITE_DOCUMENTS';

const teamWriteReady = createAction(TEAM_WRITE_READY);
const teamWriteDocLoaded = createAction(
  TEAM_WRITE_DOC_LOADED,
  props<{ key: string }>(),
);
const teamWriteResetDoc = createAction(RESET_TEAM_WRITE_DOC);
const teamWriteSaveDoc = createAction(SAVE_TEAM_WRITE_DOC);
const teamWriteNewDoc = createAction(NEW_TEAM_WRITE_DOC);
const teamWriteLoadDoc = createAction(LOAD_TEAM_WRITE_DOC);
const teamWriteDeleteDoc = createAction(DELETE_TEAM_WRITE_DOC);
const teamWriteTrashDoc = createAction(TRASH_TEAM_WRITE_DOC);
const teamWriteRestoreDoc = createAction(RESTORE_TEAM_WRITE_DOC);

export const TeamWriteActions = {
  teamWriteReady,
  teamWriteDocLoaded,
  teamWriteResetDoc,
  teamWriteSaveDoc,
  teamWriteNewDoc,
  teamWriteLoadDoc,
  teamWriteDeleteDoc,
  teamWriteTrashDoc,
  teamWriteRestoreDoc,
};
