import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { createHttpLink } from '@apollo/client/link/http';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { getToken } from '@common/services/pl-http';

import { PLGraphQLService } from './pl-graph-ql.service';
import { PLGQLClientsService } from './pl-graph-ql-clients.service';
import { PLGraphQLErrorService } from './pl-graph-ql-error.service';
import { environment } from '@root/src/environments/environment';

@NgModule({
  imports: [ApolloModule],
  providers: [PLGraphQLService, PLGraphQLErrorService, PLGQLClientsService],
})
export class PLGraphQLModule {
  static forRoot(): ModuleWithProviders<PLGraphQLModule> {
    return {
      ngModule: PLGraphQLModule,
      providers: [
        {
          provide: APOLLO_OPTIONS,
          useFactory() {
            const httpLink = createHttpLink({
              uri: environment.apps.apollo.url,
              fetch: fetch,
            });
            const authLink = new ApolloLink((operation, forward) => {
              operation.setContext({
                headers: {
                  authorization: `JWT ${getToken()}`,
                },
              });
              return forward(operation);
            });

            return {
              cache: new InMemoryCache(),
              link: ApolloLink.from([authLink, httpLink]),
            };
          },
        },
      ],
    };
  }
}

export { PLGraphQLService } from './pl-graph-ql.service';
export { PLGQLClientsService } from './pl-graph-ql-clients.service';
export { PLGraphQLErrorService } from './pl-graph-ql-error.service';
