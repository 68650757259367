import {
  Observable,
  from,
  throwError,
  of,
  EMPTY,
  BehaviorSubject,
  Subject,
} from 'rxjs';
import {
  ConferenceProvider,
  JoinConfig,
  MediaSource,
  ConferenceStatus,
  CommandMsg,
  FilterVideoMode,
} from './conference-provider/conference-provider';
import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { ConferenceStreamType } from './conference-provider/conference-stream';
import { fromNodeCallback } from '../rxjs';
import { FilterVideoType } from '../../modules/room/conference/store';
import { LoggerService } from '../services/logger/logger.service';

export interface TokboxConfig {
  apiKey: string;
  connectionToken: string;
  conferenceId: string;
}

export interface TokboxJoinConfig extends JoinConfig {
  fps?: 15 | 30 | 7 | 1;
  resolution?:
    | '640x480'
    | '1280x960'
    | '1280x720'
    | '640x360'
    | '320x240'
    | '320x180';
}

export const DefaultBroadcastOptions: TokboxJoinConfig = {
  fps: 15,
  resolution: '640x480',
};

type PublishFn = (
  publisher: OT.Publisher,
  callback?: (error?: OT.OTError) => void,
) => OT.Publisher;

interface SignalMsg {
  type?: string;
  data?: string;
}

interface SignalConfig extends SignalMsg {
  to?: OT.Connection;
}

interface SingalEvent extends SignalMsg {
  from?: OT.Connection;
}

export class TokboxConferenceProvider extends ConferenceProvider {
  private session: OT.Session;

  private connections = new Map<string, OT.Connection>();

  private publishers = new Map<string, OT.Publisher>();
  private publisherDisposers = new Map<string, VoidFunction>();

  private subscribers = new Map<string, OT.Subscriber>();
  private remoteStreams = new Map<string, OT.Stream>();

  private remoteStreamSubjects = new Map<
    string,
    BehaviorSubject<OT.Stream | null>
  >();
  private remoteStreamCreatedSubject = new Subject<string>();

  private readonly omitTokxboxErroNames: string[] = [
    'OT_USER_MEDIA_ACCESS_DENIED',
    'OT_NO_DEVICES_FOUND',
  ];

  get config() {
    return this._config;
  }

  constructor(private _config: TokboxConfig, private logger: LoggerService) {
    super();

    const { apiKey, conferenceId } = this.config;

    this.session = OT?.initSession(apiKey, conferenceId, {
      ipWhitelist: true,
    });

    this.setupRemoteStreamsListeners();
    this.setupConnectionListeners();
    this.setupConnectionsListeners();
  }

  init(): Observable<void> {
    if (typeof OT === 'undefined') {
      this.setStatus(ConferenceStatus.disconnected);
      return throwError(new Error('OT script is not available'));
    }

    return this.connect();
  }

  connect() {
    const { connectionToken } = this.config;
    this.setStatus(ConferenceStatus.connecting);
    return this._connect(connectionToken).pipe(
      tap({
        error: () => {
          this.setStatus(ConferenceStatus.disconnected);
        },
      }),
    );
  }

  dispose(): void {
    this.emit('disconnected');
    for (const pubDispose of this.publisherDisposers.values()) {
      pubDispose();
    }
    this.session.off();
    this.session.disconnect();
  }

  join(id: string, config?: TokboxJoinConfig): Observable<void> {
    const pub = this.createPublisher(id, config);
    return this.publish(pub);
  }

  leave(id: string): void {
    if (!this.publishers.has(id)) {
      return;
    }
    this.session.unpublish(this.publishers.get(id));
  }

  setVideoSource(id: string, source: string): Observable<void> {
    return this.getPublisherOrFail(id).pipe(
      switchMap(pub => {
        return from(pub.setVideoSource(source)).pipe(
          tap(() => {
            this.emit('changed', this.getStream(id));
          }),
        );
      }),
    );
  }

  setAudioSource(id: string, source: string): Observable<void> {
    return this.getPublisherOrFail(id).pipe(
      switchMap(pub => {
        return from(pub.setAudioSource(source)).pipe(
          tap(() => {
            this.emit('changed', this.getStream(id));
          }),
        );
      }),
    );
  }

  setVideoFiltering(id: string, mode: FilterVideoMode): Observable<void> {
    if (mode.filterType === FilterVideoType.NONE) {
      return this.clearVideoFilter(id);
    }

    if (mode.filterType === FilterVideoType.BLUR) {
      return this.setVideoBlurring(id);
    }

    if (mode.filterType === FilterVideoType.BACKGROUND) {
      return this.setVideoBackground(id, mode.backgroundImageUrl);
    }

    return EMPTY;
  }

  private setVideoBlurring(id: string): Observable<void> {
    const blurFilter: OT.BackgroundBlurFilter = {
      type: 'backgroundBlur',
      blurStrength: 'high',
    };
    return this.getPublisherOrFail(id).pipe(
      switchMap(pub => {
        return from(pub.applyVideoFilter(blurFilter)).pipe(
          tap(() => {
            this.emit('changed', this.getStream(id));
          }),
        );
      }),
    );
  }

  private setVideoBackground(
    id: string,
    backgroundImgUrl: string,
  ): Observable<void> {
    const backgroundFilter: OT.BackgroundReplacementFilter = {
      type: 'backgroundReplacement',
      backgroundImgUrl,
    };
    return this.getPublisherOrFail(id).pipe(
      switchMap(pub => {
        return from(pub.applyVideoFilter(backgroundFilter)).pipe(
          tap(() => {
            this.emit('changed', this.getStream(id));
          }),
        );
      }),
    );
  }

  private clearVideoFilter(id: string): Observable<void> {
    return this.getPublisherOrFail(id).pipe(
      switchMap(pub => {
        return from(pub.clearVideoFilter()).pipe(
          tap(() => {
            this.emit('changed', this.getStream(id));
          }),
        );
      }),
    );
  }

  mute(id: string): void {
    if (!this.publishers.has(id)) {
      return;
    }
    const pub = this.publishers.get(id);
    pub.publishAudio(false);
  }

  unmute(id: string): void {
    if (!this.publishers.has(id)) {
      return;
    }
    const pub = this.publishers.get(id);
    pub.publishAudio(true);
  }

  show(id: string): void {
    if (!this.publishers.has(id)) {
      return;
    }
    const pub = this.publishers.get(id);
    pub.publishVideo(true);
  }

  hide(id: string): void {
    if (!this.publishers.has(id)) {
      return;
    }
    const pub = this.publishers.get(id);
    pub.publishVideo(false);
  }

  startScreenshare(id: string): Observable<void> {
    return this.join(id, {
      videoSource: 'screen',
      resolution: '1280x720',
      isMuted: true,
      isHidden: false,
    });
  }

  stopScreenshare(id: string): void {
    return this.leave(id);
  }

  command(cmd: string, data?: string) {
    return this._signal({
      data,
      type: cmd,
    });
  }

  commandTo(to: string, cmd: string, data?: string) {
    const connection = this.connections.get(to);
    if (!connection) {
      console.warn('Stream is not in conference');
      return EMPTY;
    }

    return this._signal({
      data,
      type: cmd,
      to: connection,
    });
  }

  onCommand(cmd: string) {
    return new Observable<CommandMsg>(observer => {
      const handler = (ev: SingalEvent) => {
        const fromConnection = this.connections.get(ev.from?.connectionId);

        observer.next({
          from: fromConnection?.connectionId,
          data: ev.data,
        });
      };

      const evName = `signal:${cmd}`;
      this.session.on(evName, handler);
      observer.add(() => {
        this.session.off(evName, handler);
      });
    });
  }

  // Tokbox specific methods

  forceDisconnect(id: string) {
    if (!(this.session && this.session.capabilities.forceDisconnect)) {
      console.warn('You are not allowed to use forceDisconnect');
      return EMPTY;
    }

    if (!this.subscribers.has(id)) {
      console.warn('Subscriber not in session');
      return EMPTY;
    }

    const sub = this.subscribers.get(id);

    return fromNodeCallback(
      this.session.forceDisconnect.bind(
        this.session,
      ) as OT.Session['forceDisconnect'],
    )(sub.stream.connection);
  }

  subscribe(id: string) {
    return this.getRemoteStreamOrFail(id).pipe(
      switchMap(stream => {
        return this._subscribe(
          stream,
          // Passing undefined for the target element since the
          // insertDefaultUI option is disabled.
          // https://tokbox.com/developer/guides/customize-ui/js/#video-element
          undefined,
          { insertDefaultUI: false },
        );
      }),
    );
  }

  unsubscribe(id: string) {
    if (!this.subscribers.has(id)) {
      return;
    }
    this.session.unsubscribe(this.subscribers.get(id));
  }

  hasRemoteStream(id: string) {
    return this.getRemoteStreamSubject(id).pipe(map(Boolean));
  }

  onRemoteStreamCreated() {
    return this.remoteStreamCreatedSubject.asObservable();
  }

  private getPublisherOrFail(id: string) {
    if (!this.publishers.has(id)) {
      return throwError(new Error('Stream is not in conference'));
    }

    return of(this.publishers.get(id));
  }

  private getRemoteStreamOrFail(id: string) {
    if (!this.remoteStreams.has(id)) {
      return throwError(new Error('Stream is not in conference'));
    }

    return of(this.remoteStreams.get(id));
  }

  private createPublisher(id: string, config: TokboxJoinConfig): OT.Publisher {
    const {
      isMuted = true,
      isHidden = true,
      fps = 15,
      resolution = '640x480',
    } = config;

    const publisherData: OT.PublisherProperties = {
      resolution,
      audioSource: this.normalizeSource(config.audioSource),
      videoSource: this.normalizeSource(config.videoSource),
      name: id,
      showControls: false,
      frameRate: fps,
      insertDefaultUI: false,
      publishAudio: !isMuted,
      publishVideo: !isHidden,
    };

    if (config.filterMode?.filterType === FilterVideoType.BLUR) {
      publisherData.videoFilter = {
        type: 'backgroundBlur',
        blurStrength: 'high',
      };
    } else if (config.filterMode?.filterType === FilterVideoType.BACKGROUND) {
      publisherData.videoFilter = {
        type: 'backgroundReplacement',
        backgroundImgUrl: config.filterMode.backgroundImageUrl,
      };
    }

    const pub = OT.initPublisher(
      // Passing undefined for the target element since the
      // insertDefaultUI option is disabled.
      // https://tokbox.com/developer/guides/customize-ui/js/#video-element
      undefined,
      publisherData,
      (error: OT.OTError) => {
        if (error) {
          console.error(
            'There was an error while initializing the publisher',
            error,
          );
          if (!this.omitTokxboxErroNames.includes(error.name) && this.logger) {
            this.logger.logEvent('Error initializing tokbox publisher', {
              level: 'error',
              tags: {
                errorName: error.name,
                errorMessage: error.message,
              },
            });
          }
        }
      },
    );

    this.publishers.set(id, pub);
    this.setPublisherEvents(id, pub);
    return pub;
  }

  private setPublisherEvents(id: string, pub: OT.Publisher) {
    let pubMediaStream: MediaStream;
    let pubStream: OT.Stream;

    pub.on('streamCreated', ({ stream }) => {
      pubStream = stream;
      this.tryEmitJoined(id, pubStream, pubMediaStream);
    });

    pub.on('videoElementCreated', ({ element }) => {
      pubMediaStream = (element as HTMLVideoElement).srcObject as MediaStream;
      element.setAttribute('data-stream-id', id);
      this.tryEmitJoined(
        id,
        pubStream,
        pubMediaStream,
        element as HTMLVideoElement,
      );
    });

    pub.on('streamDestroyed', ({ reason }) => {
      if (!this.getStream(id)) {
        return;
      }
      this.publisherDisposers.delete(id);
      this.publishers.delete(id);
      this.emit('left', {
        id,
        reason,
      });
    });

    this.publisherDisposers.set(id, () => {
      pub.off();
    });
  }

  private tryEmitJoined = (
    id: string,
    stream?: OT.Stream,
    mediaStream?: MediaStream,
    videoElement?: HTMLVideoElement,
  ) => {
    if (stream && mediaStream) {
      const { audioSource, videoSource } = this.getDevices(id);

      this.emit('joined', {
        id,
        mediaStream,
        audioSource,
        videoSource,
        type: this.getStreamType(stream),
        providerId: stream.streamId,
        videoElement,
      });
    }
  };

  private getDevices(id: string) {
    const { getAudioSource, getVideoSource } = this.publishers.get(id);

    let audioSource: string;
    let videoSource: string;

    const pubAudioSource = getAudioSource();
    if (pubAudioSource) {
      audioSource = pubAudioSource.getSettings().deviceId;
    }

    const pubVideoSource = getVideoSource();
    if (pubVideoSource) {
      videoSource = pubVideoSource.deviceId;
    }

    return {
      audioSource,
      videoSource,
    };
  }

  private normalizeSource(source?: MediaSource) {
    // Sending 'true' to tokbox results in an invalid source warning
    if (source === true) {
      return undefined;
    }

    return source;
  }

  private setupRemoteStreamsListeners() {
    this.session.on('streamCreated', ({ stream }) => {
      const streamId = stream.name;
      if (streamId) {
        this.setRemoteStream(streamId, stream);
      }
    });

    this.session.on('streamPropertyChanged', ({ stream }) => {
      const streamId = stream.name;
      const conferenceStream = this.getStream(streamId);
      if (!conferenceStream) {
        return;
      }
      this.emit('changed', this.getStream(streamId));
    });

    this.session.on('streamDestroyed', ({ reason, stream }) => {
      const streamId = stream.name;
      if (!this.getStream(streamId)) {
        return;
      }
      this.subscribers.delete(streamId);
      this.deleteRemoteStream(streamId);
      this.emit('left', {
        reason,
        id: streamId,
      });
    });
  }

  private setupConnectionListeners() {
    this.session.on('sessionConnected', () => {
      this.emit('connected');
    });

    this.session.on('sessionDisconnected', () => {
      this.emit('disconnected');
    });

    this.session.on('sessionReconnecting', () => {
      // Tokbox reconnection not working in firefox
      this.dispose();
    });
  }

  private setupConnectionsListeners() {
    this.session.on('connectionCreated', ev => {
      const { connection } = ev;
      const { connectionId } = connection;
      this.connections.set(connectionId, connection);
      this.emit('remoteConnected', { id: connectionId });
    });

    this.session.on('connectionDestroyed', ev => {
      const { connection } = ev;
      const { connectionId } = connection;
      this.connections.delete(connectionId);
      this.emit('remoteDisconnected', { id: connectionId });
    });
  }

  private getStreamType(stream: OT.Stream) {
    if (stream.videoType === 'camera') {
      return ConferenceStreamType.camera;
    }

    if (stream.videoType === 'screen') {
      return ConferenceStreamType.screen;
    }

    return ConferenceStreamType.custom;
  }

  private _connect(token: string) {
    return fromNodeCallback(
      this.session.connect.bind(this.session) as OT.Session['connect'],
    )(token);
  }

  private _signal(singalConfig: SignalConfig) {
    const config = { ...singalConfig };
    const isConnected = this.getStatusValue() === ConferenceStatus.connected;

    if (!isConnected) {
      console.warn('Unable to send signal. Tokbox not connected', config);
      return EMPTY;
    }

    if (!config.data) {
      delete config.data;
    }

    if (!config.to) {
      delete config.to;
    }

    return fromNodeCallback(
      this.session.signal.bind(this.session) as OT.Session['signal'],
    )(config).pipe(
      catchError(err => {
        if (err?.name === 'OT_NOT_CONNECTED') {
          console.warn('Unable to send signal. Tokbox not connected', config);
          return EMPTY;
        }

        return throwError(err);
      }),
    );
  }

  private publish(publisher: OT.Publisher) {
    return fromNodeCallback(
      this.session.publish.bind(this.session) as PublishFn,
    )(publisher);
  }

  private _subscribe(
    stream: OT.Stream,
    targetElement?: HTMLElement | string,
    properties?: OT.SubscriberProperties,
  ) {
    const sub = this.session.subscribe(stream, targetElement, properties);

    const streamId = stream.name;

    this.subscribers.set(streamId, sub);

    return new Observable<OT.Subscriber>(subscriber => {
      const handler = ({ element }) => {
        element.setAttribute('data-stream-id', streamId);
        this.emit('joined', {
          id: streamId,
          providerId: stream.streamId,
          mediaStream: (element as HTMLVideoElement).srcObject as MediaStream,
          type: this.getStreamType(stream),
          videoElement: element,
        });
        subscriber.next(sub);
        subscriber.complete();
      };

      const EVENT = 'videoElementCreated';

      sub.on(EVENT, handler);
      subscriber.add(() => {
        sub.off(EVENT, handler);
      });
    });
  }

  private setRemoteStream(id: string, stream: OT.Stream) {
    this.remoteStreamCreatedSubject.next(id);
    this.remoteStreams.set(id, stream);
    this.getRemoteStreamSubject(id).next(stream);
  }

  private deleteRemoteStream(id: string) {
    this.remoteStreams.delete(id);
    this.getRemoteStreamSubject(id).next(null);
  }

  private getRemoteStreamSubject(id: string) {
    if (!this.remoteStreamSubjects.has(id)) {
      this.remoteStreamSubjects.set(
        id,
        new BehaviorSubject<OT.Stream | null>(null),
      );
    }
    return this.remoteStreamSubjects.get(id);
  }
}
