import { AppState } from '@app/store';
import { createSelector } from '@ngrx/store';
import { NetworkStatus } from '../services';
import { windowFeatureKey } from './window.model';

export const selectFeature = (app: AppState) => app[windowFeatureKey];

export const selectNetworkStatus = createSelector(
  selectFeature,
  state => state.networkStatus,
);
export const selectIsOnline = createSelector(
  selectNetworkStatus,
  networkStatus => networkStatus === NetworkStatus.online,
);
export const selectIsReconnecting = createSelector(
  selectNetworkStatus,
  networkStatus => networkStatus === NetworkStatus.reconnecting,
);
export const selectIsOffline = createSelector(
  selectNetworkStatus,
  networkStatus => networkStatus === NetworkStatus.offline,
);

export const selectVisibilityState = createSelector(
  selectFeature,
  state => state.visibilityState,
);
export const selectIsVisible = createSelector(
  selectVisibilityState,
  visibilityState => visibilityState === 'visible',
);
export const selectIsHidden = createSelector(
  selectVisibilityState,
  visibilityState => visibilityState === 'hidden',
);
