import { Injectable } from '@angular/core';
import {
  getClient,
  createConsoleLogger,
  LogLevel,
  PollingMode,
  IConfigCatClient,
  User as ConfigCatUser,
} from 'configcat-js';
import { filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';

import { environment } from '@root/src/environments/environment';
import { FeatureFlag } from './feature-flags.constant';
import { FeatureFlagsService } from './feature-flags.service';
import { User } from '@root/src/app/modules/user/user.model';

@Injectable()
export class ConfigCatFeatureFlagsService implements FeatureFlagsService {
  private client: IConfigCatClient;

  private clientConfigChanged$ = new Subject<void>();

  private user$ = new BehaviorSubject<ConfigCatUser>(null);

  private featureFlagValues$ = new BehaviorSubject<
    Record<FeatureFlag, boolean>
  >({} as Record<FeatureFlag, boolean>);

  constructor() {
    const logger = createConsoleLogger(
      environment.debug ? LogLevel.Warn : LogLevel.Error,
    );

    this.client = getClient(environment.config_cat_sdk, PollingMode.AutoPoll, {
      pollIntervalSeconds: 60,
      logger,
      setupHooks: hooks => {
        hooks.on('configChanged', () => {
          this.clientConfigChanged$.next();
        });
        hooks.on('clientReady', () => {
          this.clientConfigChanged$.next();
        });
      },
    });

    combineLatest([this.clientConfigChanged$, this.user$])
      .pipe(
        filter(([, user]) => Boolean(user)),
        switchMap(([, user]) => this.getAllFeatures(user)),
      )
      .subscribe(this.featureFlagValues$);
  }

  public identifyUser(user: User, roomName: string = null): void {
    this.user$.next(
      new ConfigCatUser(user.uuid, user.email, null, {
        roomName,
        username: user.username,
      }),
    );
  }

  public isFeatureEnabled(flagName: FeatureFlag): Observable<boolean> {
    return this.featureFlagValues$.pipe(
      filter(f => Object.keys(f).length > 0),
      map(features => features[flagName] || false),
    );
  }

  private async getAllFeatures(
    user: ConfigCatUser,
  ): Promise<Record<FeatureFlag, boolean>> {
    const values = await this.client.getAllValuesAsync(user);
    return values.reduce(
      (obj, next) => {
        // eslint-disable-next-line no-param-reassign
        obj[next.settingKey] = next.settingValue as boolean;
        return obj;
      },
      {} as Record<FeatureFlag, boolean>,
    );
  }
}
