import { createReducer, on } from '@ngrx/store';
import { LibraryState, LibraryActions } from '@library/store';

const initialState: LibraryState = {
  userRoom: null,
  userQueues: null,
  queuesServiceInitialized: false,
  lastDeletedQueueId: null,
};

export const reducer = createReducer(
  initialState,
  on(LibraryActions.setUserRoomSuccess, (state, { userRoom }) => {
    return {
      ...state,
      userRoom,
    };
  }),
  on(LibraryActions.setQueuesServiceInitialized, (state, { initialized }) => {
    return {
      ...state,
      queuesServiceInitialized: initialized,
    };
  }),
  on(LibraryActions.fetchUserQueuesSuccess, (state, { userQueues }) => {
    return {
      ...state,
      userQueues,
    };
  }),
  on(LibraryActions.createUserQueueSuccess, (state, { queue }) => {
    return {
      ...state,
      userQueues: {
        ...state.userQueues,
        items: {
          ...(state.userQueues?.items ?? {}),
          [queue.id]: queue,
        },
        order: [...(state.userQueues?.order ?? []), queue.id],
      },
    };
  }),
  on(LibraryActions.deleteUserQueueSuccess, (state, { queueId }) => {
    const newOrder =
      state.userQueues?.order?.filter(id => id !== queueId) ?? [];
    const newItems = { ...(state.userQueues?.items ?? {}) };
    delete newItems[queueId];

    return {
      ...state,
      userQueues: {
        ...state.userQueues,
        items: newItems,
        order: newOrder,
      },
      lastDeletedQueueId: queueId,
    };
  }),
  on(LibraryActions.restoreDeletedQueueSuccess, (state, { queue }) => {
    return {
      ...state,
      lastDeletedQueueId: null,
      userQueues: {
        ...state.userQueues,
        items: {
          ...(state.userQueues?.items ?? {}),
          [queue.id]: queue,
        },
        order: [...(state.userQueues?.order ?? []), queue.id],
      },
    };
  }),
  on(LibraryActions.duplicateQueueSuccess, (state, { queue }) => {
    return {
      ...state,
      userQueues: {
        ...state.userQueues,
        items: {
          ...(state.userQueues?.items ?? {}),
          [queue.id]: queue,
        },
        order: [...(state.userQueues?.order ?? []), queue.id],
      },
    };
  }),
  on(LibraryActions.updateQueuesOrderSuccess, (state, { order }) => {
    return {
      ...state,
      userQueues: {
        ...state.userQueues,
        order,
      },
    };
  }),
  on(LibraryActions.editUserQueueSuccess, (state, { queue }) => {
    return {
      ...state,
      userQueues: {
        ...state.userQueues,
        items: {
          ...state.userQueues?.items,
          [queue.id]: queue,
        },
      },
    };
  }),
);
