import { createReducer, on } from '@ngrx/store';
import { LicensingActions } from './actions';
import { TELicensingState } from './model';

const initialState: TELicensingState = {
  isFreemiumUser: false,
  isTEProvider: false,
  remainingMinutes: 0,
  isSessionInProgress: false,
};

export const reducer = createReducer(
  initialState,
  on(
    LicensingActions.initialize,
    (state, { isFreemium, isTEProvider, minutesLeft }) => {
      return {
        ...state,
        isTEProvider,
        isFreemiumUser: isFreemium,
        remainingMinutes: minutesLeft,
      };
    },
  ),
  on(LicensingActions.startSession, (state, { sessionData }) => {
    return {
      ...state,
      isSessionInProgress: true,
      currentSessionData: sessionData,
    };
  }),
  on(LicensingActions.stopSession, state => {
    return {
      ...state,
      isSessionInProgress: false,
      currentSessionData: null,
    };
  }),
  on(LicensingActions.decreaseMinute, state => {
    return {
      ...state,
      remainingMinutes: state.remainingMinutes ? state.remainingMinutes - 1 : 0,
    };
  }),
  on(
    LicensingActions.setUserLicensing,
    (state, { isFreemium, isTEProvider }) => {
      return {
        ...state,
        isTEProvider,
        isFreemiumUser: isFreemium,
      };
    },
  ),
  on(LicensingActions.setRemaningMinutes, (state, { minutesLeft }) => {
    return {
      ...state,
      remainingMinutes: minutesLeft,
    };
  }),
);
