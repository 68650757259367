import { Component } from '@angular/core';
import { ZenDeskService } from '../../services/zendesk.service';

@Component({
  selector: 'pl-window-warning-dialog',
  templateUrl: 'window-warning-dialog.component.html',
  styleUrls: ['./window-warning-dialog.component.less'],
})
export class PLWindowWarningDialogComponent {
  constructor(private zendeskService: ZenDeskService) {}

  onStartChat() {
    this.zendeskService.startChat();
  }
}
