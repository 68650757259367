import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { environment } from '@root/src/environments/environment';

import { LoggerEventMeta, LoggerService } from './logger.service';
import { User } from '@root/src/app/modules/user/user.model';

@Injectable()
export class SentryLoggerService implements LoggerService {
  static init() {
    if (!environment.sentry_key) return;

    const tracePropagationTargets = [
      // TODO: Uncomment these lines  once APIs on production
      // support CORS for trace propagation headers from Sentry
      // environment.apps.apiWorkplace.url,
      // environment.apps.apiEssentials.url,
      // environment.apps.auth.url,
      // environment.apps.platform.url,
    ];

    Sentry.init({
      dsn: environment.sentry_key,
      debug: !environment.production,
      release: environment.git_sha,
      environment: environment.env_key,
      integrations: [
        Sentry.browserTracingIntegration(),
        environment.sentry_replay_enabled &&
          Sentry.replayIntegration({
            // For privacy & security reasons, we don't record any text or media
            // we can adjust it and explicitly block parts of the app if we want to
            maskAllText: true,
            blockAllMedia: true,
          }),
      ],
      tracePropagationTargets: tracePropagationTargets,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: environment.production ? 0.25 : 1.0,

      // 10% of sessions will be recorded as part of replays
      replaysSessionSampleRate: 0.1,

      // 50% of sessions with error will trigger recording as part of replays
      replaysOnErrorSampleRate: 0.5,

      denyUrls: [
        /https:\/\/enterprise\.opentok\.com\/.*\/js\/opentok(\.min)?\.js/,
      ],

      ignoreErrors: [
        'Unexpected token',
        `SyntaxError: expected expression, got '<'`,
        `Cannot read property 'socketId' of null`,
        '10 $digest() iterations reached.',
        `TypeError: Cannot read property 'element' of undefined`,
        'Permission denied',
        'Non-Error promise rejection',
        'OT_USER_MEDIA_ACCESS_DENIED',
        'OT_INVALID_PARAMETER',
        'OT_UNEXPECTED_SERVER_RESPONSE',
        'ResizeObserver loop limit exceeded',
        'AbortError: The user aborted a request.',
        'set failed: value argument contains undefined in property',
        'Non-Error exception captured with keys',
        'permission denied at',
        `Client doesn't have permission to access the desired data`,
        'ResizeObserver loop completed with undelivered notifications',
        `Cannot read properties of null (reading 'is')`,
      ],
    });
  }

  static createErrorHandler() {
    return Sentry.createErrorHandler({
      logErrors: !environment.production,
    });
  }

  public identifyUser(user: User, { isHost }: { isHost: boolean }) {
    Sentry.setUser({
      id: user.uuid,
      email: user.email,
      groups: user.groups,
      isObserver: user.isObserver,
      isHost,
    });
  }

  public logEvent(message: string, meta?: LoggerEventMeta): void {
    Sentry.captureEvent({
      message,
      level: meta?.level,
      tags: meta?.tags,
    });
  }

  public error(err: any, meta?: LoggerEventMeta): void {
    Sentry.captureException(
      err,
      meta
        ? {
            level: meta.level,
            tags: meta.tags,
          }
        : null,
    );
  }
}
