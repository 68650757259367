import { Injectable } from '@angular/core';
import { PLUrlsService, PLHttpService } from '@common/services/pl-http';
import { DRFCollectionService } from './DRFCollection.service';
import { DRFModel } from './DRFModel.service';

@Injectable({ providedIn: 'root' })
export class DRFRoomModel extends DRFModel {
  public collectionModel: DRFCollectionService;
  constructor(http: PLHttpService, plUrls: PLUrlsService) {
    super(`${plUrls.urls.platformFE}/api/v3/room/`, http);
    this.collectionModel = new DRFCollectionService(
      `${plUrls.urls.platformFE}/api/v3/room/`,
      http,
    );
  }
}
