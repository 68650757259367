import { PLHijackDrawerModule } from './common/components/pl-hijack-drawer/index';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PLTimezoneService, PLCommonServicesModule } from '@common/services';

import { FirebaseResolverService } from '@common/firebase/proxy-resolver.service';
import { FirebaseEffects } from '@common/firebase/store/firebase.effects';
import { PLApiModule } from '@common/services/api';
import { PLGraphQLModule } from '@common/services/pl-graph-ql';
import { PLHttpModule } from '@common/services/pl-http';
import { MediaEffects } from '@common/media/store';

import { PLUserModule } from '@modules/user';
import { AppEffects } from '@room/app/store';

import { extModules } from './build-specifics';
import { AppComponent } from './app.component';
import { appStore } from './store';
import { AppConfigService } from './app-config.service';
import { SvgToMatIconsRegistryService } from './common/services/svg-to-mat-icons-registry.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { WindowModule } from './common/window';
import { WaitingRoomModule } from './common/waiting-room';
import { createErrorHandler } from './common/services/logger/logger.service';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS } from '@angular/material/progress-bar';
import { AppRoutingModule } from './app-routing.module';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategyService } from './page-title-strategy.service';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PLHttpModule,
    PLApiModule,
    PLGraphQLModule.forRoot(),
    HotkeyModule.forRoot(),
    StoreModule.forRoot(appStore),
    EffectsModule.forRoot([FirebaseEffects, AppEffects, MediaEffects]),
    WaitingRoomModule,
    PLCommonServicesModule,
    PLUserModule,
    WindowModule,
    AppRoutingModule,
    PLHijackDrawerModule,
    ...extModules,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapInitializer,
      multi: true,
      deps: [
        AppConfigService,
        FirebaseResolverService,
        SvgToMatIconsRegistryService,
      ],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        // TODO: Set default color as accent after upgrading to version 14
        // color: 'accent',
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: 'accent',
      },
    },
    {
      provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
      useValue: {
        color: 'accent',
      },
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategyService,
    },
    PLTimezoneService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function bootstrapInitializer(
  appConfigService: AppConfigService,
  firebaseResolverService: FirebaseResolverService,
  svgToMatIconsRegistryService: SvgToMatIconsRegistryService,
) {
  return async () => {
    await firebaseResolverService.load();

    appConfigService.checkForConfig();

    svgToMatIconsRegistryService.register();
  };
}
