import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, timer } from 'rxjs';
import {
  exhaustMap,
  map,
  mergeMap,
  retryWhen,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AppState } from '../../store';
import { ConferenceStatus } from './conference-provider';
import { ConferenceService } from './conference.service';

import * as fromWindow from '@common/window/store/window.selectors';
import { LeavingPageService } from '../window/services';

class ConferenceConnectionMonitor {
  constructor(
    private store: Store<AppState>,
    private leavingPageService: LeavingPageService,
    private conference: ConferenceService,
  ) {}

  exec() {
    return this.shouldReconnect().pipe(
      takeUntil(this.leavingPageService.onLeavingPage()),
      exhaustMap(shouldReconnect => {
        if (shouldReconnect) {
          return this.tryReconnection();
        }
        return EMPTY;
      }),
    );
  }

  private shouldReconnect() {
    return combineLatest([
      this.store.select(fromWindow.selectIsOnline),
      this.isConferenceDisconnected(),
    ]).pipe(map(([isDisconnected, isOnline]) => isDisconnected && isOnline));
  }

  private isConferenceDisconnected() {
    return this.conference
      .getStatus()
      .pipe(map(status => status === ConferenceStatus.disconnected));
  }

  private tryReconnection() {
    console.log(`Attempt 1 - Trying to reconnect ${this.getName()}`);
    return this.conference.reconnect().pipe(
      tap(() => {
        console.log(`${this.getName()} reconnected!`);
      }),
      retryWhen(errors => {
        return errors.pipe(
          mergeMap((err, index) => {
            console.log(
              `Attempt ${index + 2} - Trying to reconnect ${this.getName()}`,
              err,
            );
            return timer(1_000);
          }),
        );
      }),
    );
  }

  private getName() {
    return this.conference.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ConferenceReconnectionService {
  constructor(
    private store: Store<AppState>,
    private leavingPageService: LeavingPageService,
  ) {}

  monitorConnection(conference: ConferenceService) {
    return new ConferenceConnectionMonitor(
      this.store,
      this.leavingPageService,
      conference,
    ).exec();
  }
}
