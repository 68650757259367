<div class="assumed-login-bar" [ngClass]="{ expanded: isExpanded }">
  <div class="assumed-login-bar__tab" (click)="onClickTab()">
    <div class="assumed-login-bar__tab-visual">
      <mat-icon class="chevron-icon" inline>
        {{ isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
      </mat-icon>
    </div>
  </div>
  <div class="assumed-login-bar__body">
    <p>You are currently working on behalf of {{ username }}</p>
    <button
      mat-stroked-button
      class="release-user-btn is-small"
      (click)="onClickReleaseUser()">
      Release {{ username }}
    </button>
  </div>
</div>
