import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

export const extModules = [
  StoreDevtoolsModule.instrument({
    name: `${environment.app_name}-${environment.env_key}`,
    maxAge: 25,
    connectInZone: true,
  }),
];
