<pl-snackbar
  [actionLabel]="data.actionLabel"
  [dismissible]="data.dismissible"
  [disableAction]="data.disableAction"
  (actionClicked)="onActionClick()"
  (dismissed)="onDismiss()"
  [mode]="data.mode">
  <p>{{ data.title }}</p>
  <p *ngIf="data.description">{{ data.secondLineText }}</p>
</pl-snackbar>
