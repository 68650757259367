import type { NetworkStatus } from '../services';

import { createAction } from '@common/utils';
import { props } from '@ngrx/store';
import { windowFeatureKey } from './window.model';

export const networkStatusChanged = createAction(
  `/${windowFeatureKey}/networkStatusChanged`,
  props<{ networkStatus: NetworkStatus }>(),
);

export const visibilityChanged = createAction(
  `/${windowFeatureKey}/visibilityChanged`,
  props<{ visibilityState: DocumentVisibilityState }>(),
);
