import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategyService extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }
  override updateTitle(routerState: RouterStateSnapshot): void {
    const isLibrary = routerState.url.includes('/library');
    const module = isLibrary ? 'Library' : 'Room';
    let title = this.buildTitle(routerState);

    // Library custom generated titles
    if (isLibrary) {
      let lastChild = routerState.root;
      let maxIterations = 10;
      while (lastChild.firstChild && maxIterations > 0) {
        lastChild = lastChild.firstChild;
        maxIterations--;
      }
      const publisher = lastChild.data.publisher;
      const category = lastChild.data.category;

      if (
        routerState.url.includes('/activities') ||
        routerState.url.includes('/assessments')
      ) {
        const isAssesments = routerState.url.includes('/assessments');
        const item = lastChild.data.test || lastChild.data.activity;
        if (publisher) {
          title = `${publisher.name}${isAssesments ? ' - Assessments' : 'content'}`;
        } else if (routerState.url.includes('/preview')) {
          title = `${item.name} (preview)`;
        } else if (item) {
          title = item.name;
        } else if (category) {
          title = `${category?.name || category}${isAssesments ? ' - Assessments' : ''}`;
        }
      }
    }
    const titleSuffix = `${module} - Presence`;
    const fullTitle = title ? `${title} - ${titleSuffix}` : titleSuffix;
    this.title.setTitle(fullTitle);
  }
}
