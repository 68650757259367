import { createReducer, on } from '@ngrx/store';
import { TeamWriteActions } from './team-write.actions';
import { TeamWriteState, initialState } from './team-write.state';

const teamWriteReady = (state: TeamWriteState) => {
  return {
    ...state,
    isReady: true,
  };
};

const teamWriteDocLoaded = (state: TeamWriteState, action) => {
  return {
    ...state,
    key: action.payload.key,
    isDocLoaded: true,
  };
};

const teamWriteResetDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    resetDoc: action.payload.resetDoc,
  };
};

const teamWriteSaveDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    saveDoc: action.payload.saveDoc,
  };
};

const teamWriteNewDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    newDoc: action.payload.newDoc,
  };
};

const teamWriteLoadDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    loadingDoc: action.payload.loadingDoc,
    key: action.payload.key,
  };
};

const teamWriteDeleteDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    deletingDoc: action.payload.deletingDoc,
    key: action.payload.key,
  };
};

const teamWriteTrashDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    trashingDoc: action.payload.trashingDoc,
    key: action.payload.key,
  };
};

const teamWriteRestoreDoc = (state: TeamWriteState, action) => {
  return {
    ...state,
    restoringDoc: action.payload.restoringDoc,
    key: action.payload.key,
  };
};

export const reducer = createReducer(
  initialState,
  on(TeamWriteActions.teamWriteReady, teamWriteReady),
  on(TeamWriteActions.teamWriteDocLoaded, teamWriteDocLoaded),
  on(TeamWriteActions.teamWriteResetDoc, teamWriteResetDoc),
  on(TeamWriteActions.teamWriteDeleteDoc, teamWriteDeleteDoc),
  on(TeamWriteActions.teamWriteLoadDoc, teamWriteLoadDoc),
  on(TeamWriteActions.teamWriteNewDoc, teamWriteNewDoc),
  on(TeamWriteActions.teamWriteRestoreDoc, teamWriteRestoreDoc),
  on(TeamWriteActions.teamWriteSaveDoc, teamWriteSaveDoc),
  on(TeamWriteActions.teamWriteTrashDoc, teamWriteTrashDoc),
);
