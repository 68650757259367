import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { environment } from '@root/src/environments/environment';
import {
  LocalStorageKey,
  LocalStorageService,
} from '@common/services/LocalStorageService';

@Injectable()
export class PLInactiveService {
  // 60 minutes
  maxInactiveSeconds: number = environment.room.inactiveSeconds || 60 * 60;
  lastActiveDatetime: any = null;
  lastSaveTime: any = null;

  constructor(private localStorageService: LocalStorageService) {}

  clearLastActiveDatetime() {
    this.localStorageService.set(LocalStorageKey.INACTIVE, {
      lastActiveDatetime: '',
    });
  }

  setLastActiveDatetime(value) {
    this.lastActiveDatetime = value;
    this.saveLastActiveDatetime(value);
  }

  getLastActiveDatetime() {
    // For performance do not read from local storage if already have it
    // in memory.
    if (this.lastActiveDatetime) {
      return this.lastActiveDatetime;
    }
    let data: any = null;
    try {
      data = this.localStorageService.get(LocalStorageKey.INACTIVE);
    } catch (e) {
      console.debug('localStorage error in PLInactiveService');
    }
    let lastActiveDatetime = data ? data.lastActiveDatetime : null;
    if (lastActiveDatetime) {
      lastActiveDatetime = moment(lastActiveDatetime);
    }
    this.lastActiveDatetime = lastActiveDatetime;
    return lastActiveDatetime;
  }

  saveLastActiveDatetime(lastActiveDatetime) {
    // For performance, only save every minute.
    if (
      !this.lastSaveTime ||
      moment().diff(this.lastSaveTime, 'minutes') >= 1
    ) {
      this.localStorageService.set(LocalStorageKey.INACTIVE, {
        lastActiveDatetime,
      });
      this.lastSaveTime = moment();
    }
  }

  checkInactiveByDatetime() {
    const now = moment();
    let reset = true;
    const lastActiveDatetime = this.getLastActiveDatetime();
    if (lastActiveDatetime) {
      const diffMinutes = now.diff(lastActiveDatetime, 'minutes');
      if (diffMinutes > this.maxInactiveSeconds / 60 + 1) {
        reset = false;
      }
    }
    return {
      reset,
    };
  }
}
